import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { amc_api } from "../../utils/api_controller";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner"; // Importando o spinner para feedback visual

export default function ChangeAccountForm() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    // Função para buscar dados do usuário ao carregar o componente
    useEffect(() => {
        amc_api.get("/users/logged_user/")
            .then((res) => {
                setUserData(res.data);
                // Preencher os valores do formulário com os dados recebidos
                setValue("email", res.data.email);
                setValue("first_name", res.data.first_name);
                setValue("last_name", res.data.last_name);
                setValue("phone_number", res.data.phone_number);
                setValue("gender", res.data.gender);
                setValue("investment_profile", res.data.investment_profile);
            })
            .catch((error) => {
                console.error("Erro ao buscar dados do usuário:", error);
            });
    }, [setValue]);

    // Função para trocar a senha
    const handleChangePassword = (data) => {
        if (data.new_password !== data.passwordConfirm) {
            alert("As senhas não coincidem.");
            return;
        }
        
        setLoading(true);
        amc_api.post("/users/change_password/", 
            { new_password: data.new_password },
            {
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}` // Adicionando o token ao cabeçalho
                }
            }
        )
        .then(() => {
            alert("Senha trocada com sucesso!");
        })
        .catch((error) => {
            console.error("Erro ao trocar a senha:", error);

            // Exibindo uma mensagem mais detalhada no alert
            const errorMessage = error.response?.data?.message || "Erro desconhecido ao trocar a senha.";
            alert(`Erro ao trocar a senha: ${errorMessage}`);
        })
        .finally(() => setLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(handleChangePassword)} className="p-fluid">
            <div className="text-2xl font-bold mb-4">Perfil do Usuário</div>
            
            <div className="grid">
                {/* Campos desabilitados */}
                <div className="field col-12 md:col-6">
                    <label htmlFor="email">E-mail</label>
                    <input
                        id="email"
                        type="email"
                        {...register("email")}
                        disabled
                        className="p-inputtext p-component p-disabled" // Classe de estilo do PrimeReact
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="first_name">Nome</label>
                    <input
                        id="first_name"
                        type="text"
                        {...register("first_name")}
                        disabled
                        className="p-inputtext p-component p-disabled"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="last_name">Sobrenome</label>
                    <input
                        id="last_name"
                        type="text"
                        {...register("last_name")}
                        disabled
                        className="p-inputtext p-component p-disabled"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="phone_number">Celular</label>
                    <input
                        id="phone_number"
                        type="text"
                        {...register("phone_number")}
                        disabled
                        className="p-inputtext p-component p-disabled"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="gender">Gênero</label>
                    <input
                        id="gender"
                        type="text"
                        {...register("gender")}
                        disabled
                        className="p-inputtext p-component p-disabled"
                    />
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="investment_profile">Perfil de Investimento</label>
                    <input
                        id="investment_profile"
                        type="text"
                        {...register("investment_profile")}
                        disabled
                        className="p-inputtext p-component p-disabled"
                    />
                </div>

                {/* Campos para trocar a senha */}
                <div className="field col-12 md:col-6">
                    <label htmlFor="new_password">Nova senha</label>
                    <input
                        id="new_password"
                        type="password"
                        {...register("new_password", { required: "Nova senha é obrigatória" })}
                        className={`p-inputtext p-component ${errors.new_password ? 'p-invalid' : ''}`}
                    />
                    {errors.new_password && <small className="p-error">{errors.new_password.message}</small>}
                </div>
                <div className="field col-12 md:col-6">
                    <label htmlFor="passwordConfirm">Confirmar nova senha</label>
                    <input
                        id="passwordConfirm"
                        type="password"
                        {...register("passwordConfirm", { required: "Confirmação de senha é obrigatória" })}
                        className={`p-inputtext p-component ${errors.passwordConfirm ? 'p-invalid' : ''}`}
                    />
                    {errors.passwordConfirm && <small className="p-error">{errors.passwordConfirm.message}</small>}
                </div>
            </div>

            {/* Botão de troca de senha */}
            <Button
                type="submit"
                label={loading ? <ProgressSpinner style={{ height: "18px" }} /> : "Trocar senha"}
                className="mt-4 mb-4 w-full bg-green-600"
                disabled={loading}
            />
        </form>
    );
}
