import React from "react";

import HomeBanner from "./HomeBanner";
import SectionIntro from "./SectionIntro";
import KnowMore from "./KnowMore";
import FeaturedAlphas from "../../listAllAlphas/FeaturedAlphas";

import ReactGA from "react-ga4";

export default function Home() {
    ReactGA.send({
        hitType: "pageview",
        page: "/",
        title: "Home",
    });
    
    return (
        <>
            <HomeBanner />
            <SectionIntro />
            <FeaturedAlphas />
            <KnowMore />
        </>
    );
}
