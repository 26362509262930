import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";
import AlphaBlock from "../listAllAlphas/AlphaBlock";
import { Paginator } from "primereact/paginator";
import ReactGA from "react-ga4";

export default function TodosAlphas2() {
    const { tipoAlphaPlan: urlTipoAlphaPlan } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [maxPages, setMaxPages] = useState(1);
    const [tipoAlphaPlan, setTipoAlphaPlan] = useState("");
    const [ativo, setAtivo] = useState("");
    const [selectedBrokerage, setSelectedBrokerage] = useState(null);
    const [ordenacao, setOrdenacao] = useState('payout_diario_em_reais_asc');
    const [isAscendente, setIsAscendente] = useState(true);

    const [hasSentPlanView, setHasSentPlanView] = useState(false); // Controle de envio de view para plano

    // Mapeamento de valores para planos, ativos e ordenações
    const tiposAlphaPlan = [
        { tipo: "standard", nome: "Standard" },
        { tipo: "dynamic", nome: "Dynamic" },
        { tipo: "automated", nome: "Automated" },
    ];

    const ativos = [
        { tipo: "win", nome: "Mini Índice" },
        { tipo: "wdo", nome: "Mini Dólar" },
    ];

    const ordenacoes = [
        { tipo: 'payout_diario_em_reais_desc', nome: 'Payoff' },
        { tipo: 'acuracia_desc', nome: 'Acurácia' },
        { tipo: 'drawdown_maximo_em_reais_desc', nome: 'Drawdown' },
    ];

    const ordenacaoMap = {
        payout_diario_em_reais_desc: "PYOT",
        acuracia_desc: "ACC",
        drawdown_maximo_em_reais_desc: "DDW",
    };

    // Registrar pageview inicial ao entrar na página, mesmo sem seleções
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/todos-alphas",
            title: "Tds Alphas Inicio",
        });
    }, []);

    useEffect(() => {
        // Somente faz a requisição se o plano e ativo estiverem selecionados
        if (tipoAlphaPlan && ativo) {
            const ordenacaoComSinal = isAscendente ? ordenacao + '_asc' : ordenacao + '_desc';

            let url = `alphaportifolio/get_alphas_plans/?pagina=${currentPage + 1}&quantidade=8&tipo_alpha_plan=${tipoAlphaPlan}&ativo=${ativo}&ordenacao=${ordenacaoComSinal}`;
            if (selectedBrokerage) {
                url += `&brokerage_id=${selectedBrokerage}`;
            }

            setLoading(true); // Iniciar carregamento ao fazer requisição
            amc_api
                .get(url)
                .then((res) => {
                    setData(res.data);
                    getPagesQtd(res.data.total_produtos, res.data.quantidade_por_pagina);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false)); // Parar carregamento após a requisição
        }

        // Registrar pageviews subsequentes conforme o usuário faz seleções
        if (tipoAlphaPlan && !hasSentPlanView) {
            const plano = tiposAlphaPlan.find(item => item.tipo === tipoAlphaPlan)?.nome || "UNKNOWN";

            // Registrar apenas a primeira vez que o plano for selecionado
            ReactGA.send({
                hitType: "pageview",
                page: "/todos-alphas",
                title: `Tds Alphas | ${plano}`,
            });
            setHasSentPlanView(true); // Impede que o evento seja enviado repetidamente
        }

        if (tipoAlphaPlan && ativo && ordenacao) {
            const plano = tiposAlphaPlan.find(item => item.tipo === tipoAlphaPlan)?.nome || "UNKNOWN";
            const ativoLabel = ativos.find(item => item.tipo === ativo)?.nome || "UNKNOWN";
            const ordem = ordenacaoMap[ordenacao] || "UNKNOWN";

            ReactGA.send({
                hitType: "pageview",
                page: "/todos-alphas",
                title: `Tds Alphas | ${plano} | ${ativoLabel} | ${ordem}`,
            });
        }
    }, [currentPage, tipoAlphaPlan, ativo, selectedBrokerage, ordenacao, isAscendente, hasSentPlanView]);

    function getPagesQtd(totalProducts, qtdPerPage) {
        let pages = totalProducts / qtdPerPage;
        setMaxPages(pages > parseInt(pages) ? parseInt(pages) + 1 : parseInt(pages));
    }

    const onPageChange = (event) => {
        setCurrentPage(event.page);
    };

    const handleTipoAlphaPlan = (newTipo) => {
        if (newTipo !== tipoAlphaPlan) {
            setTipoAlphaPlan(newTipo);
            setAtivo(""); // Limpa o ativo ao mudar o plano
            setCurrentPage(0);
            setData(""); // Limpar a lista de alphas ao mudar o plano
            setHasSentPlanView(false); // Permite que um novo pageview seja enviado para o novo plano
        }
    };

    const handleAtivo = (newAtivo) => {
        if (newAtivo !== ativo) {
            setAtivo(newAtivo);
            setCurrentPage(0);
            setData(""); // Limpar a lista de alphas ao mudar o ativo
        }
    };

    const toggleAscendente = () => {
        setIsAscendente(!isAscendente);
        setCurrentPage(0);
    };

    return (
        <div className="flex p-6 text-center justify-content-center flex-column">
            {/* Mostrar sempre os botões de seleção de plano e ativo */}
            <div className="alpha-details">
                <div className="text-center text-3xl font-bold">Escolha o tipo de plano:</div>
                <div className="grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                    {tiposAlphaPlan.map((item, key) => (
                        <div
                            key={key}
                            className={
                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                (tipoAlphaPlan === item.tipo && "text-gray-400 bg-bluegray-100")
                            }
                            onClick={() => handleTipoAlphaPlan(item.tipo)}
                        >
                            {item.nome}
                        </div>
                    ))}
                </div>

                {tipoAlphaPlan && (
                    <>
                        <div className="text-center text-3xl font-bold mt-4">Escolha o ativo:</div>
                        <div className="grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer ">
                            {ativos.map((item, key) => (
                                <div
                                    key={key}
                                    className={
                                        "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                        (ativo === item.tipo && "text-gray-400 bg-bluegray-100")
                                    }
                                    onClick={() => handleAtivo(item.tipo)}
                                >
                                    {item.nome}
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>

            {/* Lógica de carregamento e exibição da lista */}
            {loading ? (
                <ProgressSpinner />
            ) : (
                ativo && tipoAlphaPlan && (
                    <div className="alpha-details mt-4">
                        {/* Exibir a lista de alphas após a seleção do plano e ativo */}
                        <div className="text-center text-3xl font-bold">Ordenação:</div>
                        <div className="grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                            {ordenacoes.map((item, key) => (
                                <div
                                    key={key}
                                    className={
                                        "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                        ((ordenacao === item.tipo || (ordenacao === 'payout_diario_em_reais_asc' && item.tipo === 'payout_diario_em_reais_desc')) && isAscendente
                                            ? "text-gray-400 bg-bluegray-100"
                                            : "")
                                    }
                                    onClick={() => setOrdenacao(item.tipo)}
                                >
                                    {item.nome}
                                </div>
                            ))}
                           <div
                                className="pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer text-gray-800 bg-white"
                                onClick={toggleAscendente}
                            >
                                {isAscendente ? "Ordem Crescente" : "Ordem Decrescente"}
                            </div>
                        </div>

                        <div className="flex justify-content-center mt-6">
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {data?.produtos?.map((produto, key) => (
                                    <AlphaBlock key={key} data={produto} />
                                ))}
                            </div>
                        </div>

                        <div className="card">
                            <Paginator
                                first={currentPage}
                                rows={1}
                                totalRecords={maxPages}
                                onPageChange={onPageChange}
                            />
                        </div>
                    </div>
                )
            )}
        </div>
    );
}
