import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import AppRoutes from "./navigation/AppRoutes";

import "primeflex/themes/primeone-light.css";
import Whatsapp from "./components/Whatsapp";

import ReactGA from "react-ga4";
import CookieConsent from "react-cookie-consent"; // Importação adicionada

export default function App() {
    const [isUrlClean, setIsUrlClean] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        
        // Verifica se há o parâmetro fbclid na query string
        if (url.searchParams.has('fbclid')) {
            url.searchParams.delete('fbclid'); // Remove o parâmetro fbclid da query string
        }

        // Remove fbclid do fragmento (hash) se presente
        if (url.hash.includes('fbclid')) {
            const hashWithoutFbclid = url.hash.replace(/&?fbclid=[^&]+/, ''); // Remove o fbclid do hash
            url.hash = hashWithoutFbclid; // Atualiza o hash sem o fbclid
        }

        // Constrói a nova URL sem fbclid e atualiza a URL
        const newUrl = url.pathname + url.search + url.hash;
        window.history.replaceState(null, '', newUrl); // Substitui a URL atualizada
        
        // Marca que a URL foi limpa
        setIsUrlClean(true);
    }, []);

    ReactGA.initialize("G-LWLJGXV8YJ");

    // Renderiza a aplicação apenas quando a URL estiver limpa
    if (!isUrlClean) {
        return null; // Ou um loader/spinner se preferir
    }

    return (
        <Router>
            <Whatsapp />
            <Header />
            <AppRoutes />
            <Footer />

            {/* Banner de Consentimento de Cookies */}
            <CookieConsent
                location="bottom"
                buttonText="Aceitar"
                declineButtonText="Recusar"
                enableDeclineButton
                onAccept={() => {
                    // Inicializar scripts após consentimento
                    ReactGA.initialize("G-LWLJGXV8YJ"); // Exemplo de inicialização do GA
                }}
                onDecline={() => {
                    // Ações caso o usuário recuse os cookies
                    // Por exemplo, desabilitar o GA ou outros scripts
                }}
                cookieName="amcCookieConsent"
                style={{ background: "#000000" }}
                buttonStyle={{
                    color: "#ffffff",
                    background: "#3b82f6",
                    fontSize: "14px",
                }}
                declineButtonStyle={{
                    color: "#ffffff",
                    background: "#808080", // Botão "Recusar" agora cinza
                    fontSize: "14px",
                }}
                expires={150}
            >
                Este site utiliza cookies do <strong>Google</strong>® para analisar a navegação e melhorar sua experiência.{" "}
                <a href="/#/politica-de-privacidade" style={{ color: "#3b82f6" }}>
                    Leia nossa Política de Privacidade
                </a>
                .
            </CookieConsent>
        </Router>
    );
}
