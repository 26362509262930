import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

export default function AlphaMetrics({ data, noDrawdown, unit, questionColor = "bg-gray", switchInterval = 0 }) {
    const [helpVisible, setHelpVisible] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const typingSpeed = 4; // Velocidade de digitação (ms por caractere)
    const scrollRef = useRef(null); // Ref para o div que conterá o texto
    const [drawdownIndex, setDrawdownIndex] = useState(0); // Índice para alternar entre os valores de drawdown

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const formatTextForDisplay = (text) => {
        if (!text) return '';

        // Negrito *EXCELENTE*
        text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

        // Itálico _italico_
        text = text.replace(/_(.*?)_/g, '<em>$1</em>');

        // Sublinhado ~sublinhado~
        text = text.replace(/~(.*?)~/g, '<u>$1</u>');

        // Tratamento para múltiplos tipos de quebra de linha (\n ou \r\n ou \r)
        text = text.replace(/(\r\n|\n|\r)/g, '<br/>');

        return text;
    };

    const toggleHelp = (section) => {
        setHelpVisible(helpVisible === section ? null : section);
    };

    const openModal = (section) => {
        const detailedText = formatTextForDisplay(data[`${section}_detalhe_observacao`]);
        setModalContent(detailedText);
        setDisplayedText(''); // Reseta o texto mostrado
        setCurrentIndex(0); // Reseta o índice atual para o efeito de digitação
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight; // Rola para o final
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setHelpVisible(null); // Fecha a caixa de ajuda ao pressionar Esc
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setDisplayedText('');
        setCurrentIndex(0);
        setHelpVisible(null);
    };

    // Alternância entre os valores do Drawdown
    useEffect(() => {
        if (switchInterval > 0) {
            const interval = setInterval(() => {
                setDrawdownIndex((prevIndex) => (prevIndex + 1) % 3); // Alterna entre 3 estados
            }, switchInterval * 1000); // Converte para milissegundos

            return () => clearInterval(interval);
        }
    }, [switchInterval]);

    const renderDrawdown = () => {

        // Verifica se o switchInterval é zero e retorna apenas o valor formatado
        if (switchInterval === 0) {
            const drawdown = data.drawdowns[0]; // Utiliza o primeiro drawdown (ou ajuste conforme necessário)

            // Formatação do valor de drawdown
            const drawdownValue = unit === "reais"
                ? formatBRL(drawdown.drawdown_em_reais)
                : `${drawdown.drawdown.toFixed(2)} pontos`;

            return (
                <span>{drawdownValue} ({drawdown.duration} dias)</span>
            );
        }

        // Seleciona o drawdown atual com base no drawdownIndex
        const drawdown = data.drawdowns[drawdownIndex]; 
    
        // Verifica se existe o drawdown no índice atual
        if (!drawdown) return null;
    
        // Formatação do índice (1º maior, 2º maior, etc.)
        const drawdownLabel = `${drawdownIndex + 1}º maior`;
    
        // Formata o valor do drawdown com base na unidade (reais ou pontos)
        const drawdownValue = unit === "reais"
            ? formatBRL(drawdown.drawdown_em_reais)
            : `${drawdown.drawdown.toFixed(2)} pontos`;
    
        // Duração do drawdown (em dias)
        const drawdownDuration = `${drawdown.duration} dias`;
    
        return (
            <span>
                <strong>{drawdownLabel}:</strong> {drawdownValue} ({drawdownDuration})<br />
            </span>
        );
    };

    const showAllText = () => {
        setDisplayedText(modalContent); // Exibe o texto completo de uma vez
        setCurrentIndex(modalContent.length); // Garante que a animação de digitação pare
    };

    return (
        <div className="shadow-1 br-1 p-2 mt-2 mb-2">
            {/* Modal Component */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef} // Ref para o div do texto
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                            dangerouslySetInnerHTML={{ __html: displayedText }}
                        />
                            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '10px' }}>
                                <Button
                                    label="Exibir Tudo"
                                    type="button"
                                    className="p-button-raised p-button-secondary"
                                    onClick={showAllText}
                                />
                                <Button
                                    label="Fechar"
                                    type="button"
                                    className="p-button-raised"
                                    onClick={closeModal}
                                />
                            </div>
                    </div>
                </div>
            )}

            {/* Layout condicional: 2x2 ou tudo em uma linha */}
            <div className={`prsy-grid ${noDrawdown ? 'prsy-col2' : 'prsy-col4'} justify-content-between text-center`}>
                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Payoff</b>
                        <span className={`question-icon ${questionColor}`} onClick={() => toggleHelp('payout')}>?</span>
                        {helpVisible === 'payout' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.payout_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('payout');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>
                        {unit === "reais" 
                            ? formatBRL(data.payout_diario_em_reais) + (!noDrawdown ? " (por dia)" : "")
                            : data.payout_diario_em_pontos.toFixed(2) + (!noDrawdown ? " pontos (por dia)" : " pontos")}
                    </span>
                </div>

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Drawdown</b>
                        <span className={`question-icon ${questionColor}`} onClick={() => toggleHelp('drawdown')}>?</span>
                        {helpVisible === 'drawdown' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.drawdown_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('drawdown');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    {/* Alterna entre os três valores de drawdown a cada switchInterval segundos */}
                    <span>
                        {renderDrawdown()}
                    </span>
                </div>

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Acurácia</b>
                        <span className={`question-icon ${questionColor}`} onClick={() => toggleHelp('acuracia')}>?</span>
                        {helpVisible === 'acuracia' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.acuracia_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('acuracia');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>{data.acuracia.toFixed(2)}%</span>
                </div>

                <div className="flex flex-column">
                    <div className="question-container">
                        <b>Total</b>
                        <span className={`question-icon ${questionColor}`} onClick={() => toggleHelp('acumulo_total')}>?</span>
                        {helpVisible === 'acumulo_total' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.acumulo_total_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('acumulo_total');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>
                        {unit === "reais" 
                            ? <>
                                {formatBRL(data.total_em_reais) + " "}
                                <strong>
                                    <span style={{ fontSize: '0.8em' }}>
                                        (
                                            {((data.total_em_reais / data.drawdown_maximo_em_reais) * 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        %)
                                    </span>
                                </strong>
                            </>
                            : `${data.total_em_pontos.toFixed(2)} pontos`}
                    </span>
                </div>

            </div>
        </div>
    );
}
