import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

export default function AlphaChart({
    chartDataLine,
    chartDataBar,
    chartDates,
    chartLabelLine,
    chartLabelBar,
    chartId,
    chartHeight,
    timeInterval = 3, // Valor padrão de 3 segundos, mas você está usando 0.
    initialChartType = "line", // Valor padrão de 'line'.
}) {
    const [chartType, setChartType] = useState(initialChartType);
    const [chartData, setChartData] = useState(initialChartType === "line" ? chartDataLine : chartDataBar);
    const [chartLabel, setChartLabel] = useState(initialChartType === "line" ? chartLabelLine : chartLabelBar);

    // Só faz a alternância se o timeInterval for maior que 0
    useEffect(() => {
        let interval;
        if (timeInterval > 0) {
            interval = setInterval(() => {
                setChartType((prevType) => (prevType === "line" ? "bar" : "line"));
            }, timeInterval * 1000); // Converte o tempo para milissegundos
        }

        return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
    }, [timeInterval]);

    // Atualiza os dados e o rótulo conforme o tipo de gráfico
    useEffect(() => {
        if (chartType === "line") {
            setChartData(chartDataLine);
            setChartLabel(chartLabelLine);
        } else {
            setChartData(chartDataBar);
            setChartLabel(chartLabelBar);
        }
    }, [chartType, chartDataLine, chartDataBar, chartLabelLine, chartLabelBar]);

    const formatDates = (dates) => {
        return dates.map((date) => {
            const parts = date.split("/");
            if (parts.length === 2) {
                return `${parts[1]}/${parts[0]}`;
            } else if (parts.length === 3) {
                return `${parts[2]}/${parts[1]}/${parts[0]}`;
            }
            return date;
        });
    };

    const formattedDates = formatDates(chartDates);

    const data = {
        labels: formattedDates,
        datasets: [
            {
                label: chartLabel,
                data: chartData,
                tension: 0.4,
                borderColor: chartType === "line" ? "#3b82f6" : undefined,
                backgroundColor: chartType === "bar" ? "rgba(59, 130, 246, 0.2)" : undefined,
                fill: chartType === "line" ? false : undefined,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
            title: {
                display: true,
                text: chartId,
                color: "#1d4ed8",
                font: {
                    size: 18,
                },
            },
            legend: {
                display: true,
                onClick: null, // Desabilita o clique na legenda para desmarcar
            },
        },
    };

    return (
        <Chart
            style={{ height: chartHeight, marginBottom: "1em" }}
            id={chartId}
            type={chartType} // Tipo de gráfico dinâmico baseado no estado
            data={data}
            options={options}
        />
    );
}
