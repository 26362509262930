import React, { useState, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import "./login.css"; // Crie um arquivo CSS específico se necessário

export default function VipLogin() {
    const [loading, setLoading] = useState(false);
    const [serverErrorMsg, setServerErrorMsg] = useState(""); // Estado para erros do servidor
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    const scrollRef = useRef(null);

    const defaultValues = {
        token: "",
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues });

    function controlForm(data) {
        setLoading(true);
        setServerErrorMsg(""); // Limpar a mensagem de erro do servidor ao submeter novamente
        // Aqui estamos utilizando o token digitado pelo usuário
        localStorage.setItem("token", data.token);
        window.location.reload(); // Recarregar a página para manter o login
    }

    function getFormErrorMessage(name) {
        if (errors[name]) {
            return <small className="p-error">{errors[name].message}</small>;
        } else if (serverErrorMsg) {
            // Exibir a mensagem de erro do servidor
            return <small className="p-error">{serverErrorMsg}</small>;
        } else {
            return <small className="p-error">&nbsp;</small>;
        }
    }

    return (
        <div className="login-container"> {/* Centralizando o formulário */}
            <form
                onSubmit={handleSubmit(controlForm)}
                className="vipLoginForm flex flex-column justify-content-center"
            >
                <div className="text-2xl font-bold mb-3">VIP Login</div>

                <Controller
                    name="token"
                    control={control}
                    rules={{ required: "O token é obrigatório." }}
                    render={({ field, fieldState }) => (
                        <div className="d-flex flex-column gap-2 w-full pt-4 p1b-4">
                            <label
                                htmlFor={field.name}
                                className={classNames({
                                    "p-error": errors.value,
                                })}
                            ></label>
                            <span className="p-float-label">
                                <InputText
                                    id={field.name}
                                    value={field.value}
                                    className={
                                        "w-full " +
                                        classNames({
                                            "p-invalid": fieldState.error,
                                        })
                                    }
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                />
                                <label htmlFor={field.name}>Token</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />

                {serverErrorMsg && (
                    <div className="mb-2">
                        <strong>Erro:</strong> {serverErrorMsg}
                    </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        type="submit"
                        label={
                            loading ? (
                                <ProgressSpinner style={{ height: "18px" }} />
                            ) : (
                                "Entrar"
                            )
                        }
                        className={"w-full mb-4"} // Ajuste de largura
                        disabled={loading}
                    />
                </div>
            </form>
        </div>
    );
}
