import Home from "../components/Layout/home/Home";
import QuemSomos from "../components/QuemSomos";
import AlphaDetails from "../components/listAllAlphas/AlphaDetails";
import Cart from "../components/cart/Cart";
import SessionStatus from "../components/cart/SessionStatus";
import Auth from "../components/auth/Auth";
import TodosAlphas2 from "../components/Layout/TodosAlphas2";
import AlphaPortifolioAutomated from "../components/portifolio/AlphaPortifolioAutomated";
import AlphaPortifolioTrader from "../components/portifolio/AlphaPortifolioTrader";
import PortifolioHome from "../components/portifolio/PortifolioHome";
import FaqNew from "../components/Layout/Faq/Faq";
import PrivacyPolicy from '../components/Layout/PrivacyPolicy';
import TermsOfUse from '../components/Layout/TermsOfUse';
import PlanosHome from "../components/planos/PlanosHome";
import VipLoginForm from "../components/auth/VipLogin"; // Importar o novo componente

export const menuLinks = [
    {
        title: "Início",
        path: "/",
        component: <Home />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Planos",
        path: "/planos",
        component: <PlanosHome />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Quem Somos",
        path: "/quem-somos",
        component: <QuemSomos />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Portfólios",
        path: "/portifolio-home",
        component: <PortifolioHome />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Todos Alphas",
        path: "/todos-alphas",
        component: <TodosAlphas2 />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Todos Alphas",
        path: "/todos-alphas/:tipoAlphaPlan?", // Parâmetro opcional para definir o tipo de plano
        component: <TodosAlphas2 />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Portfólio Automated",
        path: "/criar-portifolio-automated",
        component: <AlphaPortifolioAutomated />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Portfólio Automated",
        path: "/criar-portifolio-automated/:brokerageId?/:alphaCodeWin?/:alphaCodeWdo?/:periodo?",
        component: <AlphaPortifolioAutomated />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Portfólio Trader",
        path: "/criar-portifolio-trader",
        component: <AlphaPortifolioTrader />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Portfólio Trader",
        // Definindo parâmetros na rota para `planType`, `win_code`, `wdo_code`, e `periodo`
        path: "/criar-portifolio-trader/:planType?/:win_code?/:wdo_code?/:periodo?",
        component: <AlphaPortifolioTrader />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Portfólio Trader Promoção",
        // Definindo parâmetros na rota para `planType`, `win_code`, `wdo_code`, e `periodo`
        path: "/landing-page-portifolio-trader/:planType?/:win_code?/:wdo_code?/:periodo?",
        component: <AlphaPortifolioTrader />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "FAQ",
        path: "/faq",
        component: <FaqNew />,
        isUserOnly: false,
        isMenu: true,
    },
    {
        title: "Detalhes do Alpha",
        path: "/alpha-details/:alphaId/:periodo",
        component: <AlphaDetails />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Detalhes do Alpha",
        path: "/todos-alphas/alpha-details/:alphaId/:periodo",
        component: <AlphaDetails />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Carrinho",
        path: "/cart",
        component: <Cart />,
        isUserOnly: true,
        isMenu: false,
    },
    {
        title: "Conta",
        path: "/minha-conta",
        component: <Auth path={'/minha-conta'} />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Minha Conta VIP",
        path: "/minha-conta-vip",
        component: <VipLoginForm />, // Usando o novo componente VipLoginForm
        isUserOnly: false,
        isMenu: false, // Não aparece no menu principal
    },
    {
        title: "Compra Concluida",
        path: "/compra-concluida",
        component: <Auth path={'/minha-conta'} />,
        isUserOnly: true,
        isMenu: false,
    },
    {
        title: "Status da Compra",
        path: "/return",
        component: <SessionStatus />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Política de Privacidade",
        path: "/politica-de-privacidade",
        component: <PrivacyPolicy />,
        isUserOnly: false,
        isMenu: false,
    },
    {
        title: "Termos de Uso",
        path: "/termos-de-uso",
        component: <TermsOfUse />,
        isUserOnly: false,
        isMenu: false,
    },
];
