import React, { useState, useEffect } from 'react';
import { Chart } from "primereact/chart";

const COLORS = {
    mercado: "#3b82f6",
    bot: "#FF9800",
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            labels: {
                color: "#1d4ed8",
            },
        },
    },
    scales: {
        x: {
            ticks: {
                color: "#1d4ed8",
            },
            grid: {
                offset: true,
            },
            categoryPercentage: 0.8,
            barPercentage: 0.9,
            offset: true,
        },
        y: {
            ticks: {
                color: "#1d4ed8",
            },
            beginAtZero: true,
        },
    },
    layout: {
        padding: {
            left: 20,
            right: 20,
        },
    },
    elements: {
        bar: {
            maxBarThickness: 40,
        },
    },
};

// Função para formatar as datas de yyyy/mm para mm/yyyy
const formatDates = (dates) => {
    return dates.map((date) => {
        const [year, month, day] = date.split("/");
        return `${day}/${month}/${year}`;
    });
};

const JourneyChartTrader = ({ returns }) => {
    const [lineChartData, setLineChartData] = useState(null);
    const [barChartData, setBarChartData] = useState(null);

    useEffect(() => {
        // Configura o gráfico de linhas para as somas acumuladas
        setLineChartData({
            labels: formatDates(returns.datas),
            datasets: [
                {
                    label: "Soma Acumulada Previsão",
                    data: returns.soma_acumulada_em_reais_mercado,
                    borderColor: COLORS.mercado,
                    fill: false,
                    tension: 0.4,
                    type: 'line',
                },
            ],
        });

        // Configura o gráfico de barras para os retornos
        setBarChartData({
            labels: formatDates(returns.datas),
            datasets: [
                {
                    label: "Retornos Mercado",
                    data: returns.retornos_em_reais_mercado,
                    backgroundColor: COLORS.mercado,
                    type: 'bar',
                },
            ],
        });
    }, [returns]);

    return (
        <div>
            <div className="chart1">
                {lineChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="line"
                        data={lineChartData}
                        options={chartOptions}
                    />
                )}
            </div>
            <div className="chart2">
                {barChartData && (
                    <Chart
                        style={{ height: "300px", marginBottom: "1em" }}
                        type="bar"
                        data={barChartData}
                        options={chartOptions}
                    />
                )}
            </div>
        </div>
    );
};

export default JourneyChartTrader;
