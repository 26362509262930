import React, { useState, useEffect, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";
import { Paginator } from "primereact/paginator";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlphaMetrics from "../listAllAlphas/AlphaMetrics";
import AlphaMetricsPortifolio from "./AlphaMetricsPortifolio";
import PortfolioMetrics from "./PortfolioMetrics";
import ReactGA from "react-ga4";
import AlphaChartPortfolio from './AlphaChartPortfolio';
import PortfolioChart from './PortfolioChart';

const COLORS = {
    win: "#50c0e6",
    wdo: "#3b82f6",
    portfolio: "#FF9800",
};

const AlphaPortifolioTrader = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [alphasWin, setAlphasWin] = useState([]);
    const [alphasWdo, setAlphasWdo] = useState([]);
    const [currentPageWin, setCurrentPageWin] = useState(0);
    const [maxPagesWin, setMaxPagesWin] = useState(1);
    const [currentPageWdo, setCurrentPageWdo] = useState(0);
    const [maxPagesWdo, setMaxPagesWdo] = useState(1);
    const [selectedAlphaWin, setSelectedAlphaWin] = useState(null);
    const [selectedAlphaWdo, setSelectedAlphaWdo] = useState(null);
    const [portfolioData, setPortfolioData] = useState(null);
    const winSectionRef = useRef(null);
    const wdoSectionRef = useRef(null);
    const portfolioRef = useRef(null);
    const { planType, win_code, wdo_code, periodo } = useParams();
    const [selectedPeriod, setSelectedPeriod] = useState("12M");
    const [selectedPlanType, setSelectedPlanType] = useState("standard");
    const [ordenacao, setOrdenacao] = useState('payout_diario_em_reais_asc');
    const [isAscendente, setIsAscendente] = useState(true); // Estado para controle ascendente/descendente
    const [mostrarPromocao, setMostrarPromocao] = useState(true); // Variável para controlar a exibição da promoção
    const navigate = useNavigate();
    const location = useLocation(); // Mova o useLocation para o início do componente

    function formatBRL(number) {
        return number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";
    }

    // Função para gerar o título baseado nos parâmetros
    const generatePageTitle = () => {
        // Determina a parte da URL (landing-page ou criar-portifolio)
        const pageType = "criar";

        // Determina o tipo de plano (Standard ou Dynamic)
        const plan = selectedPlanType === "standard" ? "Standard" : "Dynamic";

        // Verifica quais ativos estão selecionados e a ação realizada
        let assetSelection;
        if (selectedAlphaWin && selectedAlphaWdo) {
            assetSelection = "Win/Wdo Show"; // Ambos os ativos selecionados e exibindo
        } else if (selectedAlphaWin) {
            assetSelection = "Win Select"; // Apenas o Win está selecionado
        } else if (selectedAlphaWdo) {
            assetSelection = "Wdo Select"; // Apenas o WDO está selecionado
        } else {
            assetSelection = "Nenhum ativo selecionado"; // Nenhum ativo selecionado
        }

        // Monta o título completo
        return `Port Trader ${pageType} ${plan} ${assetSelection}`;
    };

    useEffect(() => {
        // Tracking inicial do pageview com ReactGA usando o título gerado
        const pageTitle = generatePageTitle();

        ReactGA.send({
            hitType: "pageview",
            page: "/criar-portifolio-trader",
            title: pageTitle,
        });
    }, [selectedPlanType, selectedAlphaWin, selectedAlphaWdo, location.pathname]);
    

    // Carregar os dados iniciais da URL (parâmetros do planType, win_code, wdo_code, e periodo)
    // Sempre será executado, independentemente de navegação interna ou direta
    useEffect(() => {
        if (planType) {
            setSelectedPlanType(planType);
        }
        if (win_code && win_code !== "none") {
            setSelectedAlphaWin({ codigo: win_code });
        } else if (win_code === "none") {
            setSelectedAlphaWin(null); // Permitir que o usuário selecione um novo Alpha WIN
        }
        if (wdo_code && wdo_code !== "none") {
            setSelectedAlphaWdo({ codigo: wdo_code });
        } else if (wdo_code === "none") {
            setSelectedAlphaWdo(null); // Permitir que o usuário selecione um novo Alpha WDO
        }
        if (periodo) {
            setSelectedPeriod(periodo);
        }
    }, [planType, win_code, wdo_code, periodo]);

    // Atualizar a URL somente quando o portfólio completo for exibido
    useEffect(() => {
        if (selectedAlphaWin && selectedAlphaWdo && portfolioData) {
            const currentPath = "/criar-portifolio-trader"
            
            navigate(`${currentPath}/${selectedPlanType}/${selectedAlphaWin.codigo}/${selectedAlphaWdo.codigo}/${selectedPeriod}`, { replace: true });
        }
    }, [selectedAlphaWin, selectedAlphaWdo, portfolioData, selectedPlanType, selectedPeriod, navigate]);
    
    // Inclua esta nova função para manipular a ordenação
    const handleOrdenacao = (newOrdenacao) => {
        setLoading(true);
        setOrdenacao(newOrdenacao);
        setCurrentPageWin(0);  // Reinicia a paginação
        setCurrentPageWdo(0);
    };

    // Função para alternar entre ascendente e descendente
    const toggleAscendente = () => {
        setIsAscendente(!isAscendente);
        setLoading(true);
        setCurrentPageWin(0);
        setCurrentPageWdo(0);
    };

    // Carrega alphas do Mini Índice
    useEffect(() => {
        if (selectedPlanType) {
            setLoading(true);

            // Incluir o sinal de ordenação (ascendente ou descendente) na chamada da API
            const ordenacaoComSinal = isAscendente ? ordenacao + '_asc' : ordenacao + '_desc';

            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWin + 1
                    }&quantidade=4&tipo_alpha_plan=${selectedPlanType}&ativo=win&periodo=${selectedPeriod}&ordenacao=${ordenacaoComSinal}`
                )
                .then((res) => {
                    setAlphasWin(res.data.produtos);
                    setMaxPagesWin(Math.ceil(res.data.total_produtos / 4));
                    setTimeout(() => {
                        winSectionRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedPlanType, currentPageWin, selectedPeriod, ordenacao, isAscendente]);

    // Carrega alphas do Mini Dólar
    useEffect(() => {
        if (selectedAlphaWin) {
            setLoading(true);

            const ordenacaoComSinal = isAscendente ? ordenacao + '_asc' : ordenacao + '_desc';

            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWdo + 1
                    }&quantidade=4&tipo_alpha_plan=${selectedPlanType}&ativo=wdo&periodo=${selectedPeriod}&ordenacao=${ordenacaoComSinal}`
                )
                .then((res) => {
                    setAlphasWdo(res.data.produtos);
                    setMaxPagesWdo(Math.ceil(res.data.total_produtos / 4));
                    setTimeout(() => {
                        wdoSectionRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedAlphaWin, currentPageWdo, selectedPeriod, ordenacao, isAscendente]);

    // Carrega dados do portfólio
    useEffect(() => {
        if (selectedAlphaWin && selectedAlphaWdo) {
            setLoading(true);
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: selectedPeriod,
                    granularity: "month",
                })
                .then((res) => {
                    setPortfolioData(res.data);
                    setTimeout(() => {
                        portfolioRef.current?.scrollIntoView({
                            behavior: "smooth",
                        });
                    }, 300);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    }, [selectedAlphaWdo, selectedAlphaWin, selectedPeriod]);

    const handlePeriodSelection = (period) => {
        setSelectedPeriod(period);
        setCurrentPageWin(0);   // Reinicia a página do Mini Índice
        setCurrentPageWdo(0);   // Reinicia a página do Mini Dólar
            setLoading(true);
        
        // Atualiza os dados do Alpha Mini Índice
        if (selectedAlphaWin) {
            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWin + 1
                    }&quantidade=4&tipo_alpha_plan=${selectedPlanType}&ativo=win&periodo=${period}&ordenacao=${ordenacao}`
                )
                .then((res) => {
                    setAlphasWin(res.data.produtos);
                    setMaxPagesWin(Math.ceil(res.data.total_produtos / 4));
                })
                .catch(() => setError(true));
        }
    
        // Atualiza os dados do Alpha Mini Dólar
        if (selectedAlphaWin) {
            amc_api
                .get(
                    `alphaportifolio/get_alphas_plans/?pagina=${currentPageWdo + 1
                    }&quantidade=4&tipo_alpha_plan=${selectedPlanType}&ativo=wdo&periodo=${period}&ordenacao=${ordenacao}`
                )
                .then((res) => {
                    setAlphasWdo(res.data.produtos);
                    setMaxPagesWdo(Math.ceil(res.data.total_produtos / 4));
                })
                .catch(() => setError(true));
        }
    
        // Atualiza os dados do portfólio
        if (selectedAlphaWin && selectedAlphaWdo) {
            amc_api
                .post("alphaportifolio/get_portfolio/", {
                    win_code: selectedAlphaWin.codigo,
                    wdo_code: selectedAlphaWdo.codigo,
                    periodo: period,
                    granularity: "month",
                })
                .then((res) => {
                    setPortfolioData(res.data);
                })
                .catch(() => setError(true))
                .finally(() => setLoading(false));
        }
    };
    
    const handlePlanTypeSelection = (planType) => {
        setSelectedPlanType(planType);
        setCurrentPageWin(0);   // Reinicia a página do Mini Índice
        setCurrentPageWdo(0);   // Reinicia a página do Mini Dólar
        setSelectedAlphaWin(null);   // Resetar seleção de WIN
        setSelectedAlphaWdo(null);   // Resetar seleção de WDO
        setPortfolioData(null);      // Limpar os dados do portfólio
        setLoading(true);
    };
    
    const onPageChangeWin = (event) => {
        setCurrentPageWin(event.page);
    };

    const onPageChangeWdo = (event) => {
        setCurrentPageWdo(event.page);
    };

    const handleSelectAlphaWin = (alpha) => {
        setSelectedAlphaWin(alpha);
        setCurrentPageWdo(0);
    };

    const handleSelectAlphaWdo = (alpha) => {
        setSelectedAlphaWdo(alpha);
    };

    const getAlphaCode = () => {
        return `${selectedAlphaWin.codigo},${selectedAlphaWdo.codigo}`;
    };

    const handlePurchase = () => {
        const alphaDetails = {
            alpha_code: getAlphaCode(),
            ativo: "WINWDO",
            tipo: selectedPlanType === "standard" ? "PortifolioStandard" : "PortifolioDynamic",
        };
        localStorage.setItem("purchaseDetails", JSON.stringify(alphaDetails));
        navigate("/cart");
    };
    
    const resetAlphaWin = () => {
        setSelectedAlphaWin(null);
        setAlphasWdo([]);
        setPortfolioData(null);
    };

    const resetAlphaWdo = () => {
        setSelectedAlphaWdo(null);
        setPortfolioData(null);
    };

    // Função para renderizar os gráficos do portfólio
    const renderPortfolioCharts = () => {
        if (!portfolioData) return null;

        const { win, wdo, portfolio } = portfolioData;

        return (
            <div className="container">
                <div ref={portfolioRef} className="flex flex-column w-full gap-3 mb-3">
                    <PortfolioMetrics data={portfolio.metrics} drawdown_wdo={win.metrics.drawdown_maximo_em_reais} drawdown_win={wdo.metrics.drawdown_maximo_em_reais} switchInterval={3} />
                    {/* Use o componente PortfolioChart para renderizar os gráficos */}
                    <PortfolioChart 
                        portfolioData={portfolioData}
                    />
                    <AlphaMetrics data={win.metrics} unit="reais" questionColor="bg-blue-win" />
                    <AlphaMetrics data={wdo.metrics} unit="reais" questionColor="bg-blue-wdo" />
                </div>
            </div>
        );
    };

    return (
        <div className="p-6 text-center">
            {loading ? (
                <ProgressSpinner />
            ) : (
                <>
                    {error && (
                        <div className="text-red-500">Erro ao carregar Alphas</div>
                    )}

                    {/* Seleção do tipo de plano */}
                    {selectedAlphaWin && selectedAlphaWdo && portfolioData && mostrarPromocao && (
                        <>
                            {/* Seleção do tipo de plano */}
                            <div className="text-center text-3xl font-bold">
                                Transforme Incertezas em Lucros com Duas Inteligências Artificiais Poderosas para Traders!
                            </div>

                            <div className="text-center text-3xl mt-4">
                                <strong>Dois algoritmos de inteligência artificial</strong> (Machine Learning), um para <strong>WIN</strong> e outro para <strong>WDO</strong>, operam juntos para <strong>potencializar seus resultados</strong>. Com <strong>previsões diárias</strong> precisas, essa <strong>combinação reduz significativamente o drawdown</strong> e aumenta a consistência do portfólio. <strong>Receba previsões confiáveis</strong> e transforme a soma dos payoffs em lucros consistentes, aproveitando as oportunidades de <strong>maior acurácia ao somar os retornos</strong> dos dois ativos.
                            </div>

                            <div className="text-center text-3xl font-bold mt-6">
                                Use o cupom <span className="bg-yellow">ALPHATRADERFREE30</span> para ter acesso as previsões dos movimentos por um mês GRÁTIS!
                            </div>
                        </>
                    )}

                    {/* Seleção do tipo de plano */}
                    <div className={`text-center text-3xl font-bold 'mt-6'`}>
                        Selecione o Tipo de Plano:
                    </div>
                    <div className="plans grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        {["standard", "dynamic"].map((planType, key) => (
                            <div
                                key={key}
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (selectedPlanType === planType
                                        ? "text-gray-400 bg-bluegray-100"
                                        : "")
                                }
                                onClick={() => {
                                    if (selectedPlanType !== planType) handlePlanTypeSelection(planType);
                                }}
                                disabled={selectedPlanType === planType} // Desabilita o clique se o plano estiver selecionado
                            >
                                {planType === "standard"
                                    ? "Standard (Contratos Fixos)"
                                    : "Dynamic (Contratos Dinâmicos)"}
                            </div>
                        ))}
                    </div>
                    {/* Seleção do Período */}
                    <div className="text-center text-3xl font-bold mt-4">
                        Selecione o Período:
                    </div>
                    <div className="periods grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        {[
                            { label: "Desde 2022", value: "ALL" },
                            { label: "Último Ano", value: "12M" },
                        ].map((period, key) => (
                            <div
                                key={key}
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (selectedPeriod === period.value
                                        ? "text-gray-400 bg-bluegray-100"
                                        : "")
                                }
                                onClick={() => {
                                    if (selectedPeriod !== period.value) handlePeriodSelection(period.value);
                                }}
                                disabled={selectedPeriod === period.value} // Desabilita o clique se o período estiver selecionado
                            >
                                {period.label}
                            </div>
                        ))}
                    </div>

                    {!portfolioData && (
                        <>
                            {/* Botões de Ordenação */}
                            <div className="text-center text-3xl font-bold mt-4">
                                Ordenar por:
                            </div>
                            <div className="ordenacao grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                                {[
                                    { tipo: 'payout_diario_em_reais', nome: 'Payoff' },
                                    { tipo: 'acuracia', nome: 'Acurácia' },
                                    { tipo: 'drawdown_maximo_em_reais', nome: 'Drawdown' }
                                ].map((item, key) => (
                                    <div
                                        key={key}
                                        className={
                                            "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer " +
                                            (ordenacao.startsWith(item.tipo)  // Verifica se a ordenação atual é baseada nesse item
                                                ? "text-gray-400 bg-bluegray-100" // Aplica o estilo cinza se for a ordenação atual
                                                : "")
                                        }
                                        onClick={() => {
                                            if (!ordenacao.startsWith(item.tipo)) handleOrdenacao(item.tipo);
                                        }}
                                        disabled={ordenacao.startsWith(item.tipo)} // Desabilita se já for a ordenação atual
                                    >
                                        {item.nome}
                                    </div>
                                ))}
                                {/* Botão crescente/decrescente */}
                                <div
                                    className="pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer text-gray-800 bg-white"
                                    onClick={toggleAscendente}
                                >
                                    {isAscendente ? "Ordem Crescente" : "Ordem Decrescente"}
                                </div>
                            </div>
                        </>
                    )}

                    {/* Renderiza a lista de Alphas Mini Índice */}
                    {selectedPlanType && !selectedAlphaWin && (
                        <div ref={winSectionRef} className="alpha-list mt-5">
                            <div className="text-center text-3xl font-bold">
                                Selecione o Alpha Mini Índice
                            </div>
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {alphasWin.map((produto, key) => (
                                    <AlphaBlockPortfolio
                                        key={key}
                                        data={produto}
                                        selectedPeriod={selectedPeriod}
                                        onSelectAlpha={handleSelectAlphaWin}
                                    />
                                ))}
                            </div>
                            <div className="card">
                                <Paginator
                                    first={currentPageWin}
                                    rows={1}
                                    totalRecords={maxPagesWin}
                                    onPageChange={onPageChangeWin}
                                />
                            </div>
                        </div>
                    )}

                    {/* Renderiza a lista de Alphas Mini Dólar */}
                    {selectedAlphaWin && !portfolioData && (
                        <div ref={wdoSectionRef} className="alpha-list mt-5">
                            <div className="text-center text-3xl font-bold">
                                Selecione o Alpha Mini Dólar
                            </div>
                            <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                                {alphasWdo.map((produto, key) => (
                                    <AlphaBlockPortfolio
                                        key={key}
                                        data={produto}
                                        selectedPeriod={selectedPeriod}
                                        onSelectAlpha={handleSelectAlphaWdo}
                                    />
                                ))}
                            </div>
                            <div className="card">
                                <Paginator
                                    first={currentPageWdo}
                                    rows={1}
                                    totalRecords={maxPagesWdo}
                                    onPageChange={onPageChangeWdo}
                                />
                            </div>
                        </div>
                    )}

                    {/* Exibe os gráficos de portfólio */}
                    {selectedAlphaWin && selectedAlphaWdo && portfolioData && (
                        <div className="container">
                            <div className="header mt-5 mb-2 p-1 prsy-flex align-items-center justify-content-between">
                                <div className="identification text-left">
                                    <div className="text-3xl font-bold">
                                        Portfólio {selectedPlanType}: 
                                    </div>
                                    <div className="text-1xl">
                                        + {portfolioData.win?.metrics?.alpha_description} Quantidade de contratos por dia: {portfolioData.win?.metrics?.quant_maxima_de_contratos}
                                    </div>
                                    <div className="text-1xl">
                                        + {portfolioData.wdo?.metrics?.alpha_description} Quantidade de contratos por dia: {portfolioData.wdo?.metrics?.quant_maxima_de_contratos}
                                    </div>
                                </div>
                                <div className="cta">
                                    <div className="flex gap-3">
                                        <Button
                                            label={`Comprar por ${formatBRL(selectedPlanType === "standard" ? 348 : 428)} por mês`}
                                            type="button"
                                            className="p-button-raised w-full"
                                            onClick={handlePurchase}
                                        />
                                        <Button
                                            label="Escolher novo Alpha Mini Índice"
                                            type="button"
                                            className="p-button-outlined w-full win-button"
                                            onClick={resetAlphaWin}
                                        />

                                        <Button
                                            label="Escolher novo Alpha Mini Dólar"
                                            type="button"
                                            className="p-button-outlined w-full wdo-button"
                                            onClick={resetAlphaWdo}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {portfolioData && renderPortfolioCharts()}
                </>
            )}
        </div>
    );
};

function AlphaBlockPortfolio({ data, selectedPeriod, onSelectAlpha }) {
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [errorDetails, setErrorDetails] = useState(false);
    const [alphaDetails, setAlphaDetails] = useState(null);

    useEffect(() => {
        amc_api
            .get(
                `alphaportifolio/get_details/?alpha_code=${data.codigo}&granularity=month&periodo=${selectedPeriod}`
            )
            .then((res) => {
                setAlphaDetails(res.data);
            })
            .catch(() => setErrorDetails(true))
            .finally(() => setLoadingDetails(false));
    }, [data.codigo, selectedPeriod]);

    function formatBRL(number) {
        return number
            ? number.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
              })
            : "R$ 0,00";
    }

    return (
        <Card className="md:w-25rem">
            <div className="p-card-title" data-pc-section="title">
                {data.nome_ativo}
            </div>
            <div className="p-card-title" data-pc-section="title">
                {data.tipo}
            </div>
            <div class="p-card-subtitle" data-pc-section="subtitle">
                {data.descricao}. Max. contratos por operação: {data.max_contratos}.
            </div>
            {loadingDetails ? (
                <ProgressSpinner />
            ) : errorDetails ? (
                <>Erro ao carregar detalhes.</>
            ) : (
                <>
                    <AlphaChartPortfolio
                        chartDataLine={
                            alphaDetails.returns.soma_acumulada_em_reais_mercado
                        }
                        chartDataBar={
                            alphaDetails.returns.retornos_em_reais_mercado
                        }
                        chartDates={alphaDetails.returns.datas}
                        chartType={"line"}
                        chartLabelLine={"Acumulado em R$"}
                        chartLabelBar={"Retornos R$ por mês"}
                        chartId={data.codigo}
                        chartHeight={"300px"}
                    />
                    <AlphaMetricsPortifolio
                        data={alphaDetails.metrics}
                        unit="reais"
                    />
                </>
            )}
            <Button
                label="Selecionar"
                type="button"
                className="p-button-raised w-full"
                onClick={() => onSelectAlpha && onSelectAlpha(data)}
            />
        </Card>
    );
}

export default AlphaPortifolioTrader;
