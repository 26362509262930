import React, { useState, useEffect } from "react";
import { amc_api, clearSession } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import ShowJourney from "./ShowJourneyAll";
import ChangeAccountForm from "./ChangeAccountForm"; 
import introJs from "intro.js";
import "intro.js/introjs.css"; 
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog"; // Importa o componente de modal

export default function MyAccount() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [activeTab, setActiveTab] = useState("Jornadas");
    const [tutorialStarted, setTutorialStarted] = useState(false);
    const [showModal, setShowModal] = useState(false); // Modal agora começa como falso
    const location = useLocation();

    // Variável para controlar se a URL é "/compra-concluida"
    const isCompraConcluida = location.pathname === "/compra-concluida";

    // Conteúdo da mensagem no modal
    const welcomeMessage = (
        <div>
            <p><strong>Parabéns pela sua compra!</strong></p>
            <p>Você receberá em breve um e-mail com os detalhes da contratação do plano ou portfólio, incluindo informações sobre os <strong>ALPHAS</strong> que escolheu.</p>
            <p>Além disso, enviaremos uma mensagem no WhatsApp com o suporte habilitado para ajudá-lo com a <strong>configuração, troca de senha, cancelamento, sugestões e dúvidas</strong>.</p>
            <p>Agora, vamos para o tutorial para que você saiba como acessar suas jornadas e perfil.</p>
        </div>
    );

    // Função para iniciar o tutorial
    const startIntro = () => {
        introJs().setOptions({
            steps: [
                { element: "#profile-tab", intro: "Aqui você pode acessar as informações do seu perfil.", position: "right" },
                { element: "#journeys-tab", intro: "Aqui estão suas jornadas. Vamos explorar como selecionar suas jornadas.", position: "right" },
                { element: "#plan-section", intro: "Aqui você pode selecionar o tipo de jornada: Standard, Dynamic ou Automated.", position: "bottom" },
                { element: "#asset-section", intro: "Após escolher o tipo de jornada, selecione o ativo: Mini Índice (WIN) ou Mini Dólar (WDO).", position: "bottom" },
                { element: "#status-section", intro: "Agora, selecione se deseja visualizar jornadas ativas ou arquivadas.", position: "bottom" },
                { element: "#logout-button", intro: "Clique aqui para sair da conta.", position: "left" }
            ],
            showStepNumbers: true,
            showBullets: false,
            nextLabel: "Próximo",
            prevLabel: "Anterior",
            doneLabel: "Concluir",
        }).start();
    };

    useEffect(() => {
        // Faz a requisição para obter os dados e desativa o spinner quando concluir
        amc_api
            .get("alphaportifolio/get_jornadas_trader/")
            .then((res) => setData(res.data))
            .catch((error) => setError(true))
            .finally(() => setLoading(false));

        // Verifica se a URL é "/compra-concluida" e se o tutorial não foi iniciado
        if (!loading && !error && data && isCompraConcluida && !tutorialStarted) {
            setShowModal(true); // Exibe o modal
            setTutorialStarted(true); 
        }
    }, [loading, data, error, tutorialStarted, isCompraConcluida]);

    return (
        <div className="container">
            {/* Modal de boas-vindas */}
            <Dialog 
                header="Bem-vindo!" 
                visible={showModal} 
                style={{ width: '80vw', maxWidth: '600px' }} // Ajuste de largura para diferentes telas
                onHide={() => setShowModal(false)} 
                closable={false}
                modal={true} // Modal centralizado
            >
                {welcomeMessage}

                {/* Div para alinhar os botões nas extremidades */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <Button 
                        label="Iniciar Tutorial" 
                        onClick={() => { setShowModal(false); startIntro(); }} 
                    />
                    <Button 
                        label="Fechar" 
                        className="p-button-secondary" 
                        onClick={() => setShowModal(false)} 
                    />
                </div>
            </Dialog>

            {loading && <ProgressSpinner />}
            {error && <>Erro ao carregar página</>}
            {data && (
                <div className="container">
                    <div className="text-3xl pt-3 font-bold">Minha Conta</div>
                    <div className="flex justify-between items-center my-4">
                        {/* Botões de Perfil e Jornadas à esquerda */}
                        <div className="flex space-x-2">
                            <Button
                                id="profile-tab" 
                                label="Perfil"
                                type="button"
                                className={`mr-3 ${activeTab === "Perfil" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("Perfil")}
                            />
                            <Button
                                id="journeys-tab"
                                label="Jornadas"
                                type="button"
                                className={`mr-3 ${activeTab === "Jornadas" ? "bg-yellow text-white borderRadius" : "bg-blue-win text-white borderRadius"}`}
                                onClick={() => setActiveTab("Jornadas")}
                            />
                        </div>
                        {/* Botão de Sair alinhado no canto direito */}
                        <div className="ml-auto">
                            <Button
                                id="logout-button" 
                                label="Sair"
                                type="button"
                                className="bg-blue-wdo text-white borderRadius"
                                onClick={clearSession}
                            />
                        </div>
                    </div>
                    {activeTab === "Jornadas" && <ShowJourney />}
                    {activeTab === "Perfil" && <ChangeAccountForm />}
                </div>
            )}
        </div>
    );
}
