import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

export default function AlphaMetricsPortifolio({ data, unit }) {
    const [helpVisible, setHelpVisible] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const typingSpeed = 3;
    const scrollRef = useRef(null);

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    const formatTextForDisplay = (text) => {
        if (!text) return '';
    
        text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
        text = text.replace(/_(.*?)_/g, '<em>$1</em>');
        text = text.replace(/~(.*?)~/g, '<u>$1</u>');
        text = text.replace(/(\r\n|\n|\r)/g, '<br/>');
    
        return text;
    };
    
    const toggleHelp = (section) => {
        setHelpVisible(helpVisible === section ? null : section);
    };

    const openModal = (section) => {
        const detailedText = formatTextForDisplay(data[`${section}_detalhe_observacao`]);
        setModalContent(detailedText);
        setDisplayedText('');
        setCurrentIndex(0);
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (isModalOpen && currentIndex < modalContent.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + modalContent[currentIndex]);
                setCurrentIndex((prev) => prev + 1);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                }
            }, typingSpeed);

            return () => clearTimeout(timeout);
        }
    }, [isModalOpen, currentIndex, modalContent]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (isModalOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen]);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
        setDisplayedText('');
        setCurrentIndex(0);
    };

    return (
        <div className="shadow-1 br-1 p-2 mt-2 mb-2">
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef}
                            className="modal-body"
                            style={{ overflowY: 'auto', maxHeight: '400px' }}
                            dangerouslySetInnerHTML={{ __html: displayedText }}
                        />
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className="prsy-grid prsy-col2 justify-content-between text-center">
                <div className="flex flex-column">
                    <div className="question-container">
                        <b>Payoff</b>
                        <span className="question-icon bg-gray" onClick={() => toggleHelp('payout')}>?</span>
                        {helpVisible === 'payout' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.payout_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('payout');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>
                        {formatBRL(data.payout_diario_em_reais) + " (por dia)"}
                    </span>
                </div>
                <div className="flex flex-column">
                    <div className="question-container">
                        <b>Drawdown</b>
                        <span className="question-icon bg-gray" onClick={() => toggleHelp('drawdown')}>?</span>
                        {helpVisible === 'drawdown' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.drawdown_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('drawdown');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>
                        {unit === "reais" 
                            ? formatBRL(data.drawdown_maximo_em_reais) 
                            : data.drawdown_maximo_em_pontos.toFixed(2) + " pontos"}
                    </span>
                </div>

            </div>

            <div className="prsy-grid prsy-col2 justify-content-between text-center mt-3">
                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Acurácia</b>
                        <span className="question-icon bg-gray" onClick={() => toggleHelp('acuracia')}>?</span>
                        {helpVisible === 'acuracia' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.acuracia_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('acuracia');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>{data.acuracia.toFixed(2)}%</span>
                </div>

                <div className="flex flex-column border-right-1 border-200">
                    <div className="question-container">
                        <b>Total</b>
                        <span className="question-icon bg-gray" onClick={() => toggleHelp('acumulo_total')}>?</span>
                        {helpVisible === 'acumulo_total' && (
                            <div className="help-box">
                                <div dangerouslySetInnerHTML={{ __html: formatTextForDisplay(data.acumulo_total_observacao) }} />
                                <br />
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    openModal('acumulo_total');
                                }}>Saiba mais</a>
                            </div>
                        )}
                    </div>
                    <span>
                        {unit === "reais" 
                            ? <>
                                {formatBRL(data.total_em_reais) + " "}
                                <strong>
                                    <span style={{ fontSize: '0.8em' }}>
                                        (
                                            {((data.total_em_reais / data.drawdown_maximo_em_reais) * 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        %)
                                    </span>
                                </strong>
                            </>
                            : `${data.total_em_pontos.toFixed(2)} pontos`}
                    </span>
                </div>
            </div>
        </div>
    );
}
