import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function PlanComparison() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    ReactGA.send({
        hitType: "pageview",
        page: "/planos",
        title: "Planos",
    });

    // Simulação de chamada de API para obter os detalhes dos planos
    useEffect(() => {
        const plansData = [
            {
                type: "Standard",
                targetAudience: "Traders iniciantes, intermediários e avançados",
                contractAmount: "Contratos fixos entre 1 e 10 por dia, conforme o Alpha escolhido.",
                operator: "Plataforma de sua preferência",
                automation: "Não",
                price: 199.00,
                support: "24x7 no WhatsApp",
                specialistSupport: "Dias úteis das 9h às 19h",
                contractDuration: "Mínimo de 1 mês",
                assets: "Mini índice e mini dólar",
                depositAmount: "0,00",
                collateral: "A partir de R$ 3.500,00. Verifique o valor do drawdown do Alpha escolhido.",
                link: "/todos-alphas/standard"
            },
            {
                type: "Dynamic",
                targetAudience: "Traders iniciantes, intermediários e avançados",
                contractAmount: "Quantidade de contratos dinâmicos, com limite máximo de 2 a 15 contratos por dia, conforme o Alpha selecionado.",
                operator: "Plataforma de sua preferência",
                automation: "Não",
                price: 249.00,
                support: "24x7 no WhatsApp",
                specialistSupport: "Dias úteis das 9h às 19h",
                contractDuration: "Mínimo de 1 mês",
                assets: "Mini índice e mini dólar",
                depositAmount: "0,00",
                collateral: "A partir de R$ 2.000,00. Verifique o valor do drawdown do Alpha escolhido.",
                link: "/todos-alphas/dynamic"
            },
            {
                type: "Automated",
                targetAudience: "Todos os investidores que não querem preocupação",
                contractAmount: "Quantidade de contratos dinâmicos, com limite máximo de 2 a 20 contratos por dia, conforme o Alpha selecionado.",
                operator: "XP Investimentos, Clear Corretora e Rico Investimentos",
                automation: "Sim",
                price: 399.00,
                support: "24x7 no WhatsApp",
                specialistSupport: "Dias úteis das 9h às 19h",
                contractDuration: "Mínimo de 1 mês",
                assets: "Mini índice e mini dólar",
                depositAmount: "0,00",
                collateral: "A partir de R$ 3.000,00. Verifique o valor do drawdown do Alpha escolhido.",
                link: "/todos-alphas/automated"
            },
        ];

        setTimeout(() => {
            setData(plansData);
            setLoading(false);
        }, 1000);  // Simulando o carregamento
    }, []);

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    return (
        <>
            <div className="flex p-6 text-center justify-content-center">
                {loading && <ProgressSpinner />}
                {error && <>Erro ao carregar Planos</>}
                {data && (
                    <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                        {data.map((plan, key) => (
                            <Card key={key} className="p-card p-component md:w-25rem flex flex-column justify-between" style={{ minHeight: '500px' }}>
                                <div className="p-card-body flex-grow-1">
                                    <div className="p-card-content">
                                        <div className="text-6xl text-blue-500 font-bold mb-3">{plan.type}</div>

                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Público-alvo:</strong><br /> {plan.targetAudience}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '81px' }}>
                                            <strong>Quantidade de Contratos:</strong><br /> {plan.contractAmount}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Ativos:</strong><br /> {plan.assets}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '65px' }}>
                                            <strong>Corretoras:</strong><br /> {plan.operator}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Período Mínimo de Contrato:</strong><br /> {plan.contractDuration}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Operações Automatizadas:</strong><br /> {plan.automation}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Valor Depósito:</strong><br />R$ {plan.depositAmount}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Valor necessário para operar:</strong><br /> {plan.collateral}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Suporte:</strong><br /> {plan.support}
                                        </div>
                                        <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                            <strong>Suporte Especializado:</strong><br /> {plan.specialistSupport}
                                        </div>
                                        <div className="p-card-subtitle mb-0" style={{ minHeight: '40px' }}>
                                            <strong>Preço:</strong><br /> {formatBRL(plan.price)} por mês
                                        </div>
                                    </div>
                                </div>

                                <div className="p-card-footer">
                                    <Button
                                        label={plan.type}
                                        type="button"
                                        className="p-button-raised w-full mt-4"
                                        onClick={() => navigate(plan.link)}
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>
                )}
            </div>

            {/* Seção dos Portfólios */}
            <div className="flex p-6 text-center justify-content-center">
                <div className="grid grid-nogutter col-12 gap-3 justify-content-center">
                    {[
                        { 
                            type: 'Portfólio Standard', 
                            primary: 'Standard', 
                            originalPrice: 398, 
                            discountedPrice: 348, 
                            discountAmount: 50, 
                            discountPercentage: '12.6%', 
                            collateral: "A partir de R$ 4.000,00. Verifique o valor do drawdown do Portfólio escolhido.",
                            link: '/criar-portifolio-trader' 
                        },
                        { 
                            type: 'Portfólio Dynamic', 
                            primary: 'Dynamic', 
                            originalPrice: 498, 
                            discountedPrice: 428, 
                            discountAmount: 70, 
                            discountPercentage: '14.0%', 
                            collateral: "A partir de R$ 5.000,00. Verifique o valor do drawdown do Portfólio escolhido.",
                            link: '/criar-portifolio-trader' 
                        },
                        { 
                            type: 'Portfólio Automated', 
                            primary: 'Automated', 
                            originalPrice: 599, 
                            discountedPrice: 499, 
                            discountAmount: 100, 
                            discountPercentage: '16.7%', 
                            collateral: "A partir de R$ 5.000,00. Verifique o valor do drawdown do Portfólio escolhido.",
                            link: '/criar-portifolio-automated' 
                        },
                    ].map((portfolio, key) => (
                        <Card key={key} className="p-card p-component md:w-25rem flex flex-column justify-between" style={{ minHeight: '300px' }}>
                            <div className="p-card-body flex-grow-1">
                                <div className="p-card-content">
                                    <div className="text-6xl text-blue-500 font-bold mb-3">{portfolio.type}</div>

                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        <strong>Inclui tudo do Plano {portfolio.primary} para os dois Alphas do Portfólio</strong>
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        * Redução de drawdown
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        ** Aumento nos retornos
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        *** Redução de volatilidade nos retornos
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        <strong>Valor de Garantia:</strong><br /> {portfolio.collateral}
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        <strong>Preço Original:</strong><br /> <s style={{ color: 'red' }}><strong>{formatBRL(portfolio.originalPrice)}</strong> por mês</s>
                                    </div>
                                    <div className="p-card-subtitle mb-4" style={{ minHeight: '40px' }}>
                                        <strong>Preço com Desconto:</strong><br /> <strong>{formatBRL(portfolio.discountedPrice)}</strong> por mês
                                    </div>
                                    <div className="p-card-subtitle mb-0" style={{ minHeight: '40px', }}>
                                        <strong>Economize:</strong><br/><span style={{ color: 'red' }}> <strong>-R$ {portfolio.discountAmount},00</strong> (<strong>{portfolio.discountPercentage}</strong> de desconto)</span>
                                    </div>
                                </div>
                            </div>

                            <div className="p-card-footer">
                                <Button
                                    label={portfolio.type}
                                    type="button"
                                    className="p-button-raised w-full mt-4"
                                    onClick={() => navigate(portfolio.link)}
                                />
                            </div>
                        </Card>
                    ))}
                </div>
            </div>

        </>
    );
}
