import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const AlphaPortifolioSelection = () => {
    const navigate = useNavigate();

    ReactGA.send({
        hitType: "pageview",
        page: "/criar-portifolio-selection",
        title: "Port. Home",
    });

    const handleTraderSelection = () => {
        navigate("/criar-portifolio-trader");
    };

    const handleAutomatedSelection = () => {
        navigate("/criar-portifolio-automated");
    };

    return (
        <div className="p-6 text-center">
            <div className="text-center text-3xl font-bold">
                Selecione sua Opção:
            </div>
            <div className="grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                <div
                    className="pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer"
                    onClick={handleTraderSelection}
                >
                    Sou Trader
                </div>
                <div
                    className="pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 cursor-pointer"
                    onClick={handleAutomatedSelection}
                >
                    Quero tudo Automatizado
                </div>
            </div>
        </div>
    );
};

export default AlphaPortifolioSelection;
