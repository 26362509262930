import React from "react";

export default function SectionIntro() {
    return (
        <div className="surface-100 p-5 text-700 text-center">
            <h2 className="text-5xl text-blue-600 font-bold mb-3">Alpha Monkey Club</h2>
            <p className="text-2xl text-700 mb-4">
                O <strong>Alpha Monkey Club</strong> é referência em <strong>análise quantitativa preditiva</strong> para o mercado financeiro, com o suporte do <strong>programa NVIDIA Inception</strong> — uma parceria que potencializa nossas soluções inovadoras de previsão. Nossa tecnologia de <strong>Inteligência Artificial</strong> processa grandes volumes de dados, ou <strong>Big Data</strong>, para gerar previsões de alta precisão para ativos como <em>mini índice</em> e <em>mini dólar</em>.
            </p>
            <p className="text-2xl text-700">
                Utilizando técnicas avançadas de <strong>Machine Learning</strong>, identificamos padrões, tendências e anomalias de mercado. Oferecemos três planos — <strong>Standard</strong>, <strong>Dynamic</strong> e <strong>Automated</strong> — para atender investidores que buscam desde previsões diárias até uma <strong>solução totalmente automatizada</strong>. Nossa missão é democratizar o acesso à tecnologia de ponta, ajudando investidores de todos os perfis a obter resultados de alta performance.
            </p>
        </div>
    );
}
