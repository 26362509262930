import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { amc_api } from "../../utils/api_controller";

import ReactGA from "react-ga4";

export default function SessionStatus() {
    ReactGA.send({
        hitType: "pageview",
        page: "/return",
        title: "Finalização de Conta",
    });

    const [status, setStatus] = useState(null);
    const [searchParams] = useSearchParams();

    const sessionId = searchParams.get("session_id");
    useEffect(() => {
        amc_api
            .get(`/users/session_status/?session_id=${sessionId}`)
            .then((res) => setStatus(res.data.status))
            .catch((error) => {});
    }, [sessionId]);

    if (status === "open") {
        return <Navigate to="/checkout" />;
    }

    if (status === "complete") {
        return <Navigate to="/compra-concluida" />;
    }

    return (
        <>
            <div className="container flex align-content-center">
                <ProgressSpinner />
            </div>
        </>
    );
}
