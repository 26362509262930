import React, { useState, useRef, useEffect } from "react";
import ShowJourneyWinTrader from "./ShowJourneyWinTrader";
import ShowJourneyWinAutomated from "./ShowJourneyWinAutomated";
import ShowJourneyWdoTrader from "./ShowJourneyWdoTrader";
import ShowJourneyWdoAutomated from "./ShowJourneyWdoAutomated";

export default function AlphaSwitcher() {
    // Estados iniciais definidos para abrir com "Automated", "Win" e "Ativas"
    const [selectedPlan, setSelectedPlan] = useState("automated"); // Estado para o plano, iniciando com "automated"
    const [selectedJourney, setSelectedJourney] = useState("win"); // Estado para o ativo, iniciando com "win"
    const [isActive, setIsActive] = useState(true); // Estado para jornadas ativas, iniciando com true (ativas)

    const ativosSectionRef = useRef(null); // Referência para a seção "Selecione o Ativo"
    const journeysSectionRef = useRef(null); // Referência para a exibição das jornadas

    const planos = [
        { tipo: "standard", nome: "Standard" },
        { tipo: "dynamic", nome: "Dynamic" },
        { tipo: "automated", nome: "Automated" },
    ];

    const ativos = [
        { tipo: "win", nome: "Mini Índice" },
        { tipo: "wdo", nome: "Mini Dólar" },
    ];

    const handleResetJourney = () => {
        setSelectedJourney("");
    };

    const handleSelectPlan = (tipo) => {
        setSelectedPlan(tipo);
        handleResetJourney();
        // Rolar para a seção "Selecione o Ativo" após selecionar o plano
        setTimeout(() => {
            ativosSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 300);
    };

    const handleSelectJourney = (tipo) => {
        setSelectedJourney(tipo);
        // Rolar para a exibição das jornadas após selecionar o ativo
        setTimeout(() => {
            journeysSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 300);
    };

    const renderJourneys = () => {
        if (!selectedPlan || !selectedJourney) return;
    
        if (selectedJourney === "win") {
            // Renderiza de acordo com o plano selecionado para 'win'
            return selectedPlan === "automated" ? (
                <ShowJourneyWinAutomated isActive={isActive} />
            ) : (
                <ShowJourneyWinTrader plan={selectedPlan} isActive={isActive} />
            );
        } else if (selectedJourney === "wdo") {
            // Renderiza de acordo com o plano selecionado para 'wdo'
            return selectedPlan === "automated" ? (
                <ShowJourneyWdoAutomated isActive={isActive} />
            ) : (
                <ShowJourneyWdoTrader plan={selectedPlan} isActive={isActive} />
            );
        }
    };
    
    useEffect(() => {
        // A rolagem automática para a seção das jornadas quando o componente é montado
        if (journeysSectionRef.current) {
            journeysSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <div className="container">
            {/* Seleção do Plano */}
            <div className="text-center mb-4" id="plan-section">
                <div className="text-center text-3xl font-bold">
                    Selecione o Tipo de Jornada:
                </div>
                <div className="plans grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                    {planos.map((item, key) => (
                        <div
                            key={key}
                            className={
                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                (selectedPlan === item.tipo ? "text-gray-400 bg-bluegray-100" : "")
                            }
                            onClick={() => handleSelectPlan(item.tipo)}
                        >
                            {item.nome}
                        </div>
                    ))}
                </div>
            </div>

            {/* Seleção do Ativo */}
            {selectedPlan && (
                <div className="text-center mb-4" ref={ativosSectionRef} id="asset-section">
                    <div className="text-center text-3xl font-bold">
                        Selecione o Ativo:
                    </div>
                    <div className="ativos grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        {ativos.map((item, key) => (
                            <div
                                key={key}
                                className={
                                    "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                    (selectedJourney === item.tipo ? "text-gray-400 bg-bluegray-100" : "")
                                }
                                onClick={() => handleSelectJourney(item.tipo)}
                            >
                                {item.nome}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Seleção de Jornadas Ativas/Arquivadas */}
            {selectedJourney && (
                <div className="text-center mb-4" id="status-section">
                    <div className="text-center text-3xl font-bold">
                        Selecione o Status da Jornada:
                    </div>
                    <div className="time-range grid gap-1 justify-content-center mt-3 text-1xl text-center cursor-pointer">
                        <div
                            className={
                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 " +
                                (isActive ? "text-gray-400 bg-bluegray-100" : "")
                            }
                            onClick={() => setIsActive(true)}
                        >
                            Jornadas Ativas
                        </div>
                        <div
                            className={
                                "pr-4 pl-4 pt-2 pb-2 shadow-1 br-1 transition-all transition-duration-400 ml-2 " +
                                (!isActive ? "text-gray-400 bg-bluegray-100" : "")
                            }
                            onClick={() => setIsActive(false)}
                        >
                            Jornadas Arquivadas
                        </div>
                    </div>
                </div>
            )}

            {/* Exibição das Jornadas */}
            <div ref={journeysSectionRef}>{renderJourneys()}</div>
        </div>
    );
}
