import React, { useState, useEffect, useMemo } from "react";
import { amc_api } from "../../utils/api_controller";
import { ProgressSpinner } from "primereact/progressspinner";
import AlphaJourneyWinTrader from "./AlphaJourneyWinTrader";
import AlphaJourneyEmpty from "./AlphaJourneyEmpty";

export default function ShowJourneyWinTrader({ plan, isActive }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [journeys, setJourneys] = useState([]);

    useEffect(() => {
        fetchJourneys();
    }, [plan, isActive]);

    const fetchJourneys = () => {
        const activeParam = isActive ? "True" : "False";

        let apiUrl = "";
        if (plan === "standard") {
            apiUrl = `/alphaportifolio/get_win_journey_standard_details/?is_active=${activeParam}`;
        } else if (plan === "dynamic") {
            apiUrl = `/alphaportifolio/get_win_journey_dynamic_details/?is_active=${activeParam}`;
        }

        setLoading(true);
        amc_api
            .get(apiUrl)
            .then((res) => setJourneys(res.data.jornadas))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };

    const renderJourneys = (journeys, Component) => {
        console.log('renderJourneys')
        return journeys.length > 0 ? (
            journeys.map((journey, index) =>
                journey.metrics.total_operacoes > 0 ? (
                    <Component key={index} data={journey} />
                ) : (
                    <AlphaJourneyEmpty key={index} data={journey} />
                )
            )
        ) : (
            <p>Nenhuma jornada encontrada.</p>
        );
    };

    // Memoriza o resultado de renderJourneys para evitar re-renderizações desnecessárias
    const memoizedJourneys = useMemo(() => renderJourneys(journeys, AlphaJourneyWinTrader), [journeys]);

    return (
        <div className="container">
            {loading && <ProgressSpinner />}
            {error && <p>Erro ao carregar as jornadas</p>}
            {!loading && !error && memoizedJourneys}
        </div>
    );
}
