import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

const AlphaChartPortfolio = ({
    chartDataLine,
    chartDataBar,
    chartDates,
    chartLabelLine,
    chartLabelBar,
    chartId,
    chartHeight,
}) => {
    const [chartType, setChartType] = useState("line"); // Estado para alternar entre os tipos de gráfico
    const [chartData, setChartData] = useState(chartDataLine);
    const [chartLabel, setChartLabel] = useState(chartLabelLine);

    // Função para alternar o gráfico entre "line" e "bar" a cada 5 segundos
    useEffect(() => {
        const interval = setInterval(() => {
            setChartType((prevType) => (prevType === "line" ? "bar" : "line"));
        }, 4000); // Alterna a cada 5 segundos

        return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
    }, []);

    // Atualiza os dados e o rótulo conforme o tipo de gráfico
    useEffect(() => {
        if (chartType === "line") {
            setChartData(chartDataLine);
            setChartLabel(chartLabelLine);
        } else {
            setChartData(chartDataBar);
            setChartLabel(chartLabelBar);
        }
    }, [chartType, chartDataLine, chartDataBar, chartLabelLine, chartLabelBar]);

    // Função para formatar as datas de yyyy/mm para mm/yyyy
    const formatDates = (dates) => {
        return dates.map((date) => {
            const [year, month] = date.split("/");
            return `${month}/${year}`;
        });
    };

    const formattedDates = formatDates(chartDates); // Formatar as datas antes de passá-las ao gráfico

    const data = {
        labels: formattedDates, // Usar as datas formatadas
        datasets: [
            {
                label: chartLabel,
                data: chartData,
                tension: 0.4,
                borderColor: chartType === "line" ? "#3b82f6" : undefined,
                backgroundColor:
                    chartType === "bar" ? "rgba(59, 130, 246, 0.2)" : undefined,
                fill: chartType === "line" ? false : undefined,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: chartId,
                color: "#1d4ed8",
                font: {
                    size: 18,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: "#1d4ed8",
                },
            },
            y: {
                ticks: {
                    color: "#1d4ed8",
                },
            },
        },
    };

    return (
        <Chart
            style={{ height: chartHeight, marginBottom: "1em" }}
            id={chartId}
            type={chartType} // Tipo de gráfico dinâmico
            data={data}
            options={options}
        />
    );
};

export default AlphaChartPortfolio;
