import React, { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import FaqCategory from "./FaqCategory";
import ReactGA from "react-ga4";

export default function FaqNew() {
    ReactGA.send({
        hitType: "pageview",
        page: "/faq",
        title: "FAQ / Sobre a AMC",
    });

    const faqData = [
        {
            category: "Quem Somos",
            subcategories: [
                {
                    subcategory: "Visão Geral",
                    questions: [
                        {
                            question: "O que é o Alpha Monkey Club (AMC)?",
                            answer: "O Alpha Monkey Club é uma solução avançada de Big Data e Machine Learning voltada para o mercado financeiro, especializada em modelos quantitativos preditivos que oferecem previsões de alta precisão para derivativos mini índice e mini dólar."
                        },
                        {
                            question: "Qual é a missão do Alpha Monkey Club?",
                            answer: "A missão do Alpha Monkey Club é democratizar o acesso a ferramentas científicas e sistemáticas de investimento, utilizando tecnologia de ponta.",
                        },
                        {
                            question: "Quais são os principais serviços oferecidos pelo Alpha Monkey Club?",
                            answer: "O Alpha Monkey Club oferece serviços de previsão de mercado financeiro, utilizando Big Data e Machine Learning para derivativos mini índice e mini dólar. Esses serviços incluem análises preditivas de alta precisão, relatórios detalhados e ferramentas de acompanhamento de desempenho.",
                        },
                        {
                            question: "Como o Alpha Monkey Club utiliza a tecnologia para previsões de mercado?",
                            answer: "O Alpha Monkey Club utiliza tecnologia de ponta em Big Data e Machine Learning para construir modelos quantitativos preditivos, analisando dados históricos e atuais do mercado financeiro para gerar previsões precisas para derivativos mini índice e mini dólar."
                        },
                        {
                            question: "Quais são os diferenciais do Alpha Monkey Club?",
                            answer: "Os diferenciais do Alpha Monkey Club incluem previsões de alta precisão com base em modelos quantitativos preditivos, análise de Big Data e Machine Learning, uma plataforma amigável para todos os níveis de investidores e um suporte contínuo ao usuário."
                        },
                    ],
                },
                {
                    subcategory: "História e Origem",
                    questions: [
                        {
                            question: "Qual é a origem do nome 'Alpha Monkey Club'?",
                            answer: "O nome combina o termo 'alpha', que se refere ao desempenho superior no mercado financeiro, com 'Monkey Club', uma homenagem à história sobre como a bolsa de valores começou com macacos, simbolizando a imprevisibilidade e volatilidade do mercado.",
                        },
                        {
                            question: "Como o Alpha Monkey Club começou?",
                            answer: "O Alpha Monkey Club foi fundado com o objetivo de democratizar o acesso a ferramentas avançadas de análise de mercado, utilizando big data, machine learning e matemática aplicada.",
                        },
                        {
                            question: "Quem são os fundadores do Alpha Monkey Club?",
                            answer: "O Alpha Monkey Club foi fundado por um grupo de especialistas em tecnologia de dados, machine learning e matemática aplicada.",
                        },
                        {
                            question: "Como surgiu a ideia de criar o Alpha Monkey Club?",
                            answer: "A ideia de criar o Alpha Monkey Club surgiu da visão dos fundadores em enfrentar a alta complexidade de problemas financeiros e criar uma ferramenta inovadora para o varejo.",
                        },
                        {
                            question: "Quais foram os principais desafios enfrentados na criação do Alpha Monkey Club?",
                            answer: "Os principais desafios incluíram desenvolver algoritmos de machine learning precisos, integrar grandes volumes de dados de diversos mercados, e criar uma interface amigável tanto para especialistas quanto para pessoas comuns.",
                        },
                        {
                            question: "Qual é a parceria entre o Alpha Monkey Club e a NVIDIA?",
                            answer: "O Alpha Monkey Club é parte do programa NVIDIA Inception, que apoia startups inovadoras com recursos e suporte técnico especializado.",
                        },
                    ],
                },
            ],
        },
        {
            category: "Planos e Produtos",
            subcategories: [
                {
                    subcategory: "Sobre os planos",
                    questions: [
                        {
                            question: "Quais são os planos oferecidos pelo Alpha Monkey Club?",
                            answer: "O Alpha Monkey Club oferece três planos principais: Standard, Dynamic e Automated.",
                        },
                        {
                            question: "Qual é a diferença entre os planos Standard, Dynamic e Automated?",
                            answer: "Os planos Standard e Dynamic são voltados principalmente para traders, oferecendo previsões específicas para mini índice e mini dólar. O plano Automated é adequado para todos os investidores que desejam diversificar.",
                        },
                        {
                            question: "Para quem é indicado o plano Standard?",
                            answer: "O plano Standard é indicado para traders que buscam previsões precisas para operações de mini índice e mini dólar.",
                        },
                        {
                            question: "Quais são as vantagens do plano Dynamic?",
                            answer: "O Plano Dynamic oferece previsões diárias para o pregão e ajusta automaticamente a quantidade de contratos com base nas condições de mercado, maximizando ganhos e minimizando riscos.",
                        },
                        {
                            question: "O plano Automated é adequado para quais perfis de investidores?",
                            answer: "O plano Automated é projetado para atender a todos os perfis de investidores, desde iniciantes até experientes, oferecendo operações automatizadas com baixo drawdown.",
                        },
                    ],
                },
                {
                    subcategory: "Standard",
                    questions: [
                        {
                            question: "O que é o Plano Standard do Alpha Monkey Club?",
                            answer: "O Plano Standard oferece previsões diárias para o dia inteiro, permitindo operações baseadas em análise de Machine Learning e dados históricos.",
                        },
                        {
                            question: "Como funciona o Plano Standard?",
                            answer: "O Plano Standard oferece uma previsão para o dia inteiro, permitindo que os traders baseiem suas decisões de compra e venda em uma única análise diária.",
                        },
                        {
                            question: "Quais são os horários disponíveis para previsões no Plano Standard?",
                            answer: "O Plano Standard oferece previsões para o pregão completo, desde a abertura às 09:00 até o fechamento às 18:55.",
                        },
                        {
                            question: "O Plano Standard permite a customização de horários de previsão?",
                            answer: "Não, o Plano Standard não permite a customização de horários de previsão. Ele oferece uma única previsão para o dia completo.",
                        },
                        {
                            question: "Para quem é indicado o Plano Standard?",
                            answer: "O Plano Standard é indicado para traders que desejam previsões de mercado diárias baseadas em análises científicas e Machine Learning.",
                        },
                        {
                            question: "Como posso contratar e cancelar o Plano Standard?",
                            answer: "A contratação do Plano Standard custa R$199,00 mensais e pode ser realizada diretamente pelo nosso site. Para cancelar, basta entrar em contato com nosso suporte pelo WhatsApp.",
                        },
                        {
                            question: "O Plano Standard oferece período de teste?",
                            answer: "Atualmente, o Plano Standard não oferece período de teste gratuito.",
                        },
                    ],
                },
                {
                    subcategory: "Dynamic",
                    questions: [
                        {
                            question: "O que é o Plano Dynamic do Alpha Monkey Club?",
                            answer: "O Plano Dynamic oferece previsões otimizadas e ajusta a quantidade de contratos de acordo com as condições de mercado, maximizando ganhos e minimizando riscos.",
                        },
                        {
                            question: "Como funciona o Plano Dynamic?",
                            answer: "O Plano Dynamic ajusta automaticamente a quantidade de contratos com base nas condições de mercado, além de fornecer previsões de alta ou baixa para o dia inteiro.",
                        },
                        {
                            question: "Quais são os horários disponíveis para operar com o Plano Dynamic?",
                            answer: "Os horários são os mesmos do Plano Standard, das 09:00 às 18:55.",
                        },
                        {
                            question: "O Plano Dynamic oferece um período de teste?",
                            answer: "Atualmente, o Plano Dynamic não oferece período de teste gratuito.",
                        },
                    ],
                },
                {
                    subcategory: "Automated",
                    questions: [
                        {
                            question: "O que é o Plano Automated do Alpha Monkey Club?",
                            answer: "O Plano Automated é um serviço automatizado que executa operações de compra e venda de forma totalmente automática.",
                        },
                        {
                            question: "Quais são os diferenciais do Plano Automated?",
                            answer: "O Plano Automated se destaca pela automação completa, utilizando Machine Learning para executar operações com precisão e potencial de lucro.",
                        },
                        {
                            question: "Quais ativos estão disponíveis no Plano Automated?",
                            answer: "O Plano Automated está disponível para os ativos de mini índice e mini dólar.",
                        },
                        {
                            question: "Como posso contratar e cancelar o Plano Automated?",
                            answer: "A contratação do Plano Automated custa R$399,00 mensais e pode ser realizada diretamente pelo nosso site.",
                        },
                    ],
                },
            ],
        },
        {
            category: "Tecnologias",
            subcategories: [
                {
                    subcategory: "Dados para Previsões",
                    questions: [
                        {
                            question: "Como o Alpha Monkey Club utiliza dados históricos para fazer previsões?",
                            answer: "O Alpha Monkey Club utiliza dados históricos de mercado desde 1970 para alimentar seus modelos quantitativos preditivos, que identificam padrões e tendências para prever o comportamento de ativos como mini índice e mini dólar."
                        },
                        {
                            question: "Quais tipos de dados o Alpha Monkey Club coleta para suas análises?",
                            answer: "O Alpha Monkey Club coleta uma ampla variedade de dados, incluindo preços históricos de ativos, volumes de negociação, indicadores econômicos e financeiros.",
                        },
                        {
                            question: "Como o Alpha Monkey Club garante a precisão dos dados utilizados?",
                            answer: "O Alpha Monkey Club garante a precisão dos dados por meio de rigorosa verificação e validação antes de serem integrados aos modelos preditivos.",
                        },
                    ],
                },
                {
                    subcategory: "Machine Learning",
                    questions: [
                        {
                            question: "Como o Alpha Monkey Club utiliza Machine Learning nas suas análises?",
                            answer: "O Alpha Monkey Club utiliza técnicas avançadas de Machine Learning, como redes neurais e algoritmos preditivos, para analisar grandes volumes de dados e gerar previsões precisas para o mercado financeiro."
                        },
                        {
                            question: "Quais técnicas de Machine Learning são usadas pelo Alpha Monkey Club?",
                            answer: "O Alpha Monkey Club utiliza várias técnicas, incluindo redes neurais, regressão, árvores de decisão e técnicas de ensemble.",
                        },
                    ],
                },
            ],
        },
        {
            category: "Suporte Continuo",
            subcategories: [
                {
                    subcategory: "Como Funciona",
                    questions: [
                        {
                            question: "Como funciona o suporte no Alpha Monkey Club?",
                            answer: "O suporte é realizado pelo WhatsApp, onde os clientes podem cancelar planos, configurar automações, fazer sugestões e tirar dúvidas.",
                        },
                        {
                            question: "Como faço para acessar o suporte do Alpha Monkey Club?",
                            answer: "O suporte pode ser acessado pelo WhatsApp, disponível 24 horas por dia.",
                        },
                    ],
                },
                {
                    subcategory: "Dúvidas e Sugestões",
                    questions: [
                        {
                            question: "Como posso fazer sugestões no Alpha Monkey Club?",
                            answer: "As sugestões podem ser feitas a qualquer momento pelo WhatsApp, utilizando o assistente virtual.",
                        },
                        {
                            question: "Como posso tirar dúvidas no Alpha Monkey Club?",
                            answer: "Os usuários podem tirar dúvidas a qualquer momento pelo WhatsApp.",
                        },
                    ],
                },
            ],
        },
    ];

    const [loading] = useState(false); // Como os dados são locais, não há necessidade de setar loading
    const [error] = useState(false); // Sem erro se os dados são locais

    return (
        <>
            <div className="pt-6 container">
                <div className="text-900 font-bold text-5xl mb-3">
                    Perguntas Frequentes
                </div>
                <div className="flex flex-column">
                    {loading && <ProgressSpinner />}
                    {error && <>Erro ao carregar categoria de FAQ.</>}
                    {!loading && !error && faqData && (
                        faqData.map((faqCategory, key) => (
                            <FaqCategory key={key} faqCategory={faqCategory} />
                        ))
                    )}
                </div>
            </div>
        </>
    );
}
