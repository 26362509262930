import React from "react";
import ReactGA from "react-ga4";

export default function QuemSomos() {
    // Enviar evento para o Google Analytics
    ReactGA.send({
        hitType: "pageview",
        page: "/quem-somos",
        title: "Quem Somos",
    });

    // Dados das sessões com texto
    const sections = [
        {
            title: "O que é a Alpha Monkey Club?",
            description:
                "O Alpha Monkey Club é uma plataforma inovadora que utiliza Big Data, Machine Learning e análise quantitativa para fornecer previsões de mercado precisas. Com uma base robusta de dados históricos e algoritmos de alta performance, nossa missão é democratizar o acesso a ferramentas avançadas, ajudando traders e investidores, inclusive aqueles com pouco capital ou conhecimento de mercado, a maximizar seus resultados de forma segura, eficiente e baseada em dados.",
        },
        {
            title: "Missão, Valores e Propósito",
            description:
                "Nossa missão é fornecer previsões de mercado com a mais alta precisão, garantindo que nossos clientes tenham as melhores ferramentas para tomar decisões informadas. Valorizamos a inovação contínua, a transparência e o compromisso com práticas ESG (ambientais, sociais e de governança), gerando impacto positivo para nossos clientes e para a sociedade.",
        },
        {
            title: "Como Ajudamos Investidores",
            description:
                "Ajudamos investidores oferecendo uma ferramenta única que prevê movimentos do mercado com base em dados históricos e análises em tempo real. Seja você um trader, investidor iniciante ou alguém sem experiência no mercado, nosso sistema de inteligência artificial processa grandes volumes de dados e otimiza suas decisões para maximizar os lucros, ao mesmo tempo que minimiza os riscos.",
        },
        {
            title: "Como Funciona",
            description:
                "Nosso público-alvo inclui não apenas traders, mas qualquer pessoa que deseja obter resultados expressivos com pouco capital inicial, geralmente entre 3.000 e 6.000 reais. Com a Alpha Monkey Club, você não precisa de grandes somas ou experiência no mercado. Nossa plataforma oferece automação completa das operações e suporte dedicado, incluindo atendimento via WhatsApp, para que investidores de todos os níveis, até mesmo aqueles que nunca investiram, possam obter lucros consistentes e altos. Trabalhamos com estratégias focadas em selecionar os melhores ativos, como derivativos, maximizando seu potencial de crescimento com previsões precisas.",
        },
        {
            title: "Parceria com a NVIDIA Inception",
            description:
                "Somos orgulhosamente parceiros do programa NVIDIA Inception, o que nos permite utilizar as tecnologias mais avançadas em inteligência artificial. Aplicamos IA de ponta a ponta em nossos sistemas, desde modelos de recomendação, chatbots inteligentes, até análises preditivas. Com a NVIDIA, estamos na vanguarda da automação e otimização de processos para traders e investidores, garantindo decisões cada vez mais precisas e eficientes.",
        },
    ];

    return (
        <div className="grid grid-nogutter surface-800 text-800">
            {sections.map((section, index) => (
                <div className="col-12 p-6 text-left" key={index}>
                    <section>
                        <span className="text-6xl text-blue-500 font-bold mb-3">{section.title}</span>
                        <div className="text-2xl mt-0 mb-4 text-0 line-height-3">{section.description}</div>
                    </section>
                </div>
            ))}
        </div>
    );
}
