import React from 'react';

export default function FaqCategory({ faqCategory }) {
    return (
        <div>
            <h2>{faqCategory.category}</h2>
            {faqCategory.subcategories.map((subcat, index) => (
                <div key={index}>
                    <h3>{subcat.subcategory}</h3>
                    <ul>
                        {subcat.questions.map((question, idx) => (
                            <li key={idx}>
                                <strong>{question.question}</strong>
                                <p>{question.answer}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}
