import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import WhatsappIcon from "../../assets/WhatsApp_icon.png";
import { amc_api } from "../../utils/api_controller";
import "./login.css";

export default function LoginForm() {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [serverErrorMsg, setServerErrorMsg] = useState(""); // Estado para erros do servidor
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    const scrollRef = useRef(null);

    const defaultValues = {
        username: "",
        password: "",
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues });

    function controlForm(data) {
        setLoading(true);
        setServerErrorMsg(""); // Limpar a mensagem de erro do servidor ao submeter novamente
        amc_api
            .post("users/api_token_auth/", {
                username: data.username,
                password: data.password,
            })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
                window.location.reload();
            })
            .catch((error) => {
                // Exibir uma mensagem de erro amigável
                if (error.response && error.response.status === 401) {
                    setServerErrorMsg("Nome de usuário ou senha inválidos.");
                } else if (error.response && error.response.status === 400) {
                    setServerErrorMsg("Nome de usuário ou senha inválidos.");
                } else {
                    setServerErrorMsg("Ocorreu um erro inesperado. Tente novamente mais tarde.");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function getFormErrorMessage(name) {
        if (errors[name]) {
            return <small className="p-error">{errors[name].message}</small>;
        } else if (serverErrorMsg) {
            // Exibir a mensagem de erro do servidor
            return <small className="p-error">{serverErrorMsg}</small>;
        } else {
            return <small className="p-error">&nbsp;</small>;
        }
    }

    const openModal = () => {
        const helpText = `
            A troca de senha é realizada através do <strong>suporte 24 horas via WhatsApp</strong>. 
            Para iniciar o processo, envie qualquer mensagem para o número de suporte, e a assistente virtual será ativada automaticamente. 
            <strong>Basta digitar a palavra 'menu'</strong>, e você verá as seguintes opções de suporte: 
            - Configurações
            - Cancelamento
            - FAQ
            - Dúvidas
            - Sugestões
            - <strong>Troca de Senha</strong>.
            
            Escolha a opção 'Troca de Senha', e nosso time de suporte irá guiá-lo para redefinir sua senha de <strong>forma rápida e segura</strong>.

        `;
        setDisplayedText(helpText);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDisplayedText(""); // Limpa o texto quando o modal for fechado
    };

    useEffect(() => {
        if (isModalOpen && scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    }, [isModalOpen, displayedText]);

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            closeModal();
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("keydown", handleKeyDown);
        } else {
            document.removeEventListener("keydown", handleKeyDown);
        }

        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [isModalOpen]);

    return (
        <div className="loginForm">
            <form
                onSubmit={handleSubmit(controlForm)}
                className="loginForm flex flex-column flex justify-content-center"
            >
                <div className="text-2xl font-bold mb-3">Entrar</div> {/* Adicionando margem inferior */}

                <Controller
                    name="username"
                    control={control}
                    rules={{ required: "Este campo não pode ficar vazio." }}
                    render={({ field, fieldState }) => (
                        <div className="d-flex flex-column gap-2 w-full pt-4 p1b-4">
                            <label
                                htmlFor={field.name}
                                className={classNames({
                                    "p-error": errors.value,
                                })}
                            ></label>
                            <span className="p-float-label">
                                <InputText
                                    id={field.name}
                                    value={field.value}
                                    className={
                                        "w-full " +
                                        classNames({
                                            "p-invalid": fieldState.error,
                                        })
                                    }
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                />
                                <label htmlFor={field.name}>
                                    Nome de usuário
                                </label>
                            </span>
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Este campo não pode ficar vazio." }}
                    render={({ field, fieldState }) => (
                        <div className="d-flex flex-column pt-4 pb-4">
                            <label
                                htmlFor={field.name}
                                className={classNames({
                                    "p-error": errors.value,
                                })}
                            ></label>
                            <span className="p-float-label">
                                <Password
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e) =>
                                        field.onChange(e.target.value)
                                    }
                                    toggleMask
                                    feedback={false}
                                />
                                <label htmlFor={field.name}>Senha</label>
                            </span>
                            {getFormErrorMessage(field.name)}
                        </div>
                    )}
                />
                {errorMsg && (
                    <div className="mb-2">
                        <strong>Erro:</strong> {errorMsg}
                    </div>
                )}

                {/* Link 'Esqueci a senha' ajustado acima do botão */}
                <div className="d-flex justify-content-end mb-3">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        openModal();
                    }}>
                        Esqueci a senha
                    </a>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        type="submit"
                        label={
                            loading ? (
                                <ProgressSpinner style={{ height: "18px" }} />
                            ) : (
                                "Entrar"
                            )
                        }
                        className={"w-full mb-4"} // Ajuste de largura
                        disabled={loading}
                    />
                </div>
            </form>

            {/* Modal para instruções de troca de senha */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={closeModal}>&times;</button>
                        </div>
                        <div
                            ref={scrollRef}
                            className="modal-body"
                            style={{ overflowY: "auto", maxHeight: "400px" }}
                            dangerouslySetInnerHTML={{ __html: displayedText.replace(/\n/g, "<br />") }} // Convertendo quebras de linha
                        />
                        <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* Botão do WhatsApp ao lado do botão Fechar */}
                            <a
                                href="https://web.whatsapp.com/send?phone=15795008204&text=menu"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex align-items-center no-underline font-bold"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#2563eb",
                                    backgroundColor: "#ededed",
                                    padding: "5px 15px",
                                    borderRadius: "8px",
                                    textDecoration: "none",
                                    marginRight: "10px"
                                }}
                            >
                                <span style={{ marginRight: "5px" }}>WhatsApp</span>
                                <img
                                    style={{ width: 30 }}
                                    src={WhatsappIcon}
                                    alt="Fale conosco"
                                />
                            </a>

                            <Button
                                label="Fechar"
                                type="button"
                                className="p-button-raised"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
