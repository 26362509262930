import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import AlphaBlockDetails from "./AlphaBlockDetails";

export default function AlphaBlock({ data, timeInterval }) {
    const navigate = useNavigate();

    function formatBRL(number) {
        return number.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
    }

    return (
        <Card
            className="md:w-25rem"
        >
            <div class="p-card-title" data-pc-section="title">Plano {data.tipo}</div>
            <div class="p-card-title" data-pc-section="title">Ativo: {data.nome_ativo}</div>
            <div class="p-card-subtitle" data-pc-section="subtitle">{data.descricao}</div>
            <div class="p-card-subtitle" data-pc-section="subtitle">Max. contratos por operação: {data.max_contratos}.</div>
            <div class="p-card-subtitle" data-pc-section="subtitle">{formatBRL(parseFloat(data.price))} por mês</div>
            <AlphaBlockDetails alphaId={data.codigo} timeInterval={timeInterval} />
            <Button
                label="Saber mais"
                type="button"
                className="p-button-raised w-full"
                onClick={() => navigate(`/todos-alphas/alpha-details/${data.codigo}/${data.periodo}`)}
            />
        </Card>
    );
}
